import {Component, useState} from 'react'
import {Modal, Form, Accordion, Row, Col, Button} from 'react-bootstrap'
import {Help} from '../Icons'


class FAQModal extends Component {

    state = {
        searchText: '',
        foundAnswer: null,
        forceFoundAnswer: false,
        logId: '',
        isLoading: false,
        faqs: []
    }

    searchTerm = async () => {
        try {
            this.setState({...this.state, isLoading: true})
            const response = await fetch(`/v2/flex/faqs_open_search?terms=${this.state.searchText}&workerSid=${1}`)
            const data = await response.json()
            this.setState({...this.state, logId: data.logId})
            const faqs = data.hits.map(item => ({
                id: item['_id'],
                question: item['_source']['question'],
                answer: item['_source']['answer'],
            }))
            if (faqs.length === 0) {
                this.setState({...this.state, foundAnswer: false})
            } else {
                this.setState({...this.state, foundAnswer: null, faqs})
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({...this.state, isLoading: false})
        }
    }

    getChildren = () => {
        const ret = []
        const replaceNewLinesWithBR = (str) => {
            const ret = []
            const parts = str.split('\n')
            for (const part of parts) {
                ret.push(part)
                ret.push(<br/>)
            }
            return ret
        }
        for (const item of this.state.faqs) {
            ret.push(
                <Accordion.Item>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{replaceNewLinesWithBR(item.answer)}</Accordion.Body>
                </Accordion.Item>
            )
        }
        return ret
    }

    render() {
        return <>
            <Modal show={this.props.isOpen} onHide={this.onModalClose}>
                <Modal.Title>FAQ Query</Modal.Title>
                <Modal.Body>
                    {this.getDialogContent()}
                </Modal.Body>
            </Modal>
        </>
    }

    getDialogContent() {
        if (this.state.forceFoundAnswer) {
            return this.getFoundAnswerControl()
        }
        return <>
            <div style={{minWidth: '20vw'}}>
                {this.getSearchBox()}
                {this.state.foundAnswer === false ? <p> No results found for query</p> : null}
            </div>
            <Accordion>
                {this.getChildren()}
            </Accordion>
        </>
    }

    getSearchBox() {
        const elements = []
        elements.push(<Row>
            <Col md={8}>
                <Form.Control placeholder="Search for help"
                              fullWidth={true}
                              value={this.state.searchText}
                              onChange={(e) => this.setState({...this.state, searchText: e.target.value})}
                />
            </Col>
            <Col item md={4}>
                <Button onClick={this.searchTerm}
                        disabled={this.state.isLoading}>{this.state.isLoading ? 'Loading' : 'Search'}</Button>
            </Col>
        </Row>)
        if (this.state.faqs.length > 0) {
            elements.push(this.getFoundAnswerControl())
        }
        return elements
    }

    getFoundAnswerControl() {
        return <Form.Control>
            <Form.Label>Did you find your answer?</Form.Label>
            <Form.Group>
                <Form.Check value="Y" onChange={this.onFoundAnswerChanged} label={<Form.Label>Yes</Form.Label>}/>
                <Form.Check value="N" onChange={this.onFoundAnswerChanged} label={<Form.Label>No</Form.Label>}/>
            </Form.Group>
        </Form.Control>
    }

    onModalClose = async () => {
        const setSelectedFAQ = async () => {
        }

        if (this.state.searchText.length === 0 || this.state.logId === '') {
            this.props.onClose(false)
            return
        }
        if (this.state.foundAnswer !== null) {
            try {
                this.props.setFAQs([])
                setSelectedFAQ({...this.props, foundAnswer: this.state.foundAnswer, logId: this.state.logId})
            } finally {
                this.setState({
                    searchText: '',
                    foundAnswer: null,
                    forceFoundAnswer: false,
                    logId: '',
                    isLoading: false,
                })
            }
        } else {
            this.setState({...this.state, forceFoundAnswer: true})
        }
        this.props.onClose()
    }

    onFoundAnswerChanged = (e) => {
        this.setState({...this.state, foundAnswer: e.target.value})
    }
}

export const FAQs = () => {
    const [isOpen, setIsOpen] = useState(false)

    const tmp = ()=> {
        setIsOpen(true)
    }

    return <>
        <span onClick={tmp}>
            <Help/>
            <FAQModal isOpen={isOpen} onClose={() => setIsOpen(false)}/>
        </span>
    </>

}