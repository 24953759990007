import {useSelector} from 'react-redux'
import {Alert, Col, Row} from 'react-bootstrap'
import {connect} from '../Notes/wrapper'
import {isBroadcast, isBusiness, isDealer, useLabels} from '../../functions'
import {Transfer} from './Transfer'
import {DataViewer} from '../Utilities/DataViewer'
import {TermsAndConditions} from './TermsAndConditions'
import {ProcessCancelOrderButton} from "./ProcessCancelOrderButton";

export const Order = () => {
    const labels = useLabels()
    const orderId = useSelector(state => state.customer.data.orderId)
    const mediaType = useSelector(state => state.customer.data.mediaType)
    const script = useSelector(state => state.customer.data.script)
    const callType = useSelector(state => state.customer.data.calltype)

    const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === 'stage-crm-frontend.s3-website.us-east-2.amazonaws.com';

    let component
    // if (callType?.toLowerCase() !== 'outbound' && !isBroadcast(mediaType) && !isDealer(mediaType) && !isBusiness(script)) {
    if ( !isBroadcast(mediaType) && !isDealer(mediaType) && !isBusiness(script)) {
        component = <Transfer/>
    }


    return <Row>
        <Row className="mt-3">
            <Alert>{labels.orderDoneTitle}</Alert>
        </Row>
        <Row>
            <Col>
                <span>{labels.orderId} <b>{orderId}</b></span>
            </Col>

        </Row>
        <Row>
            <Col>
                {isDevelopment && (
                    <ProcessCancelOrderButton/>
                )}
            </Col>
        </Row>
        <TermsAndConditions/>
        {component}
        <DataViewer/>
    </Row>
}


export default connect(Order, 'order')
