import {useEffect} from 'react'
import {checkExistingCases, createNewCase, fetchCase,} from '../../state/customerAsyncActions'
import {setAdditionalArgs, setProgram} from '../../state/customerSlice'
import {useDispatch, useSelector} from 'react-redux'
import {BeatLoader} from 'react-spinners'
import {Header} from '../Header'
import {Col, Container, Row} from 'react-bootstrap'
import Order from '../Order'

import {
    detectLang,
    getSectionNotes,
    getStringTranslations,
    hideScriptButtons,
    login,
    setAppLoadingState,
    setLangScript,
    setNonPCI,
    setNotesSection,
    setOrderType,
    setShowLoginForm,
    setSimpleToken,
    setSSOToken
} from '../../state/settingsSlice'
import {AlertPopup} from '../Utilities/GeneralComponents'
import {onAfterCaseLoad} from './functions'
import '../app-loader.scss'
import {CaseSelector, LoginForm, ErrorPage} from './Common'
import {CustomerForm} from '../CustomerForm'
import {Notes} from '../Notes'

const args = {}


async function _fetchCase(caseId, program, dispatch) {
    const ret = await dispatch(fetchCase({caseId, program}))
    let caseData = ret.payload
    await onAfterCaseLoad(dispatch, caseData)
    return caseData
}

const getLoginFunc = ({dispatch, username, password}) => async () => {
    const ret = await dispatch(login({username, password}))
    const [ok] = ret.payload
    if (ok) {
        window.localStorage.creds = JSON.stringify({username, password})
        await loadForm(dispatch)
    }

    return ok
}

function validArgs(args) {
    return args.hasOwnProperty('agent_id') && args.hasOwnProperty('tfn') && args.hasOwnProperty('ani')
}

async function loadForm(dispatch) {
    let caseData
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('program')) {
        dispatch(setProgram(searchParams.get('program')))
    }
    await dispatch(getSectionNotes())
    if (searchParams.has('non-pci')) {
        dispatch(setNonPCI(true))
    }
    if (searchParams.has('caseid')) {
        caseData = await _fetchCase(searchParams.get('caseid'), searchParams.get('program'), dispatch)
        dispatch(setOrderType('new'))
    } else {
        for (const [key, value] of searchParams.entries()) {
            args[key] = value
        }
        dispatch(setAdditionalArgs(args))
        dispatch(setAppLoadingState('pending'))
        const ret = await dispatch(checkExistingCases(args))
        if (!Array.isArray(ret.payload) || ret.payload.length === 0 && validArgs(args)) {
            caseData = (await dispatch(createNewCase(args))).payload
            dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
        }
        dispatch(setAppLoadingState(''))
    }
    if (!caseData) {
        return
    }
    const {language, program, script} = caseData
    dispatch(detectLang({language, program}))
    dispatch(setLangScript(script))
}

const loadApp = async (dispatch) => {
    await dispatch(getStringTranslations())
    const searchParams = new URLSearchParams(window.location.search)

    const language = searchParams.get('language')
    const program = searchParams.get('program')
    dispatch(detectLang({language, program}))

    if (searchParams.has('hideScriptButtons')) {
        dispatch(hideScriptButtons())
    }

    if (searchParams.has('ssoToken')) {
        dispatch(setSSOToken(searchParams.get('ssoToken')))
        await loadForm(dispatch)
    } else if (searchParams.has('simpleToken') && searchParams.get('simpleToken') !== 'undefined') {
        dispatch(setSimpleToken(searchParams.get('simpleToken')))
        await loadForm(dispatch)
    } else {
        if (window.localStorage.creds) {
            const creds = JSON.parse(window.localStorage.creds)
            const loginFunc = getLoginFunc({...creds, dispatch})
            const r = await loginFunc()
            if (r) {
                return
            }
        }
        dispatch(setShowLoginForm(true))
    }
}


let loadAppCalled = false

export const AppLoader = () => {
    const applicationError = useSelector(state => state.customer.applicationError)
    const orderId = useSelector(state => state.customer.data.orderId)
    const caseId = useSelector(state => state.customer.data.caseId)
    const existingCases = useSelector(state => state.customer.cases)
    const isAgentNoteOpen = useSelector(state => state.settings.isAgentNoteOpen)
    const appLoadingState = useSelector(state => state.settings.appLoadingState)
    const showLoginForm = useSelector(state => state.settings.showLoginForm)

    function getComponent() {
        const onLogin = () => loadForm(dispatch)
        if (showLoginForm) {
            return <LoginForm onLogin={onLogin}/>
        }

        if (existingCases.length > 0) {
            return <CaseSelector args={args}/>
        }

        let component
        if (applicationError) {
            component = <ErrorPage message={applicationError}/>
        } else if (orderId) {
            component = <Order/>
        } else if (caseId) {
            component = <CustomerForm/>
        } else {
            component = <ErrorPage message={'Missing args. Make sure you have the right URL'}/>
        }
        if (isAgentNoteOpen) {
            return <Row>
                <Col md={6} as="main" className="form-panel">{component}</Col>
                <Notes/>
            </Row>
        } else {
            return <Row as="main" className="form-panel">
                {component}
            </Row>
        }
    }


    const dispatch = useDispatch()
    useEffect(() => {
        if (loadAppCalled) {
            return
        }
        loadAppCalled = true
        loadApp(dispatch)
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [])


    return (<>
        <div className="loading" style={{display: appLoadingState === 'pending' ? 'block' : ''}}>
            <div>
                <Row className="mt-3">
                    <Col md={{span: 2, offset: 5}}>
                        <div className="loading-container">
                            <BeatLoader color="#0092c5" size={40}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <Header/>
        <Container className="main-container" fluid>
            {getComponent()}
            <AlertPopup/>
        </Container>
    </>)
}

