import {Alert, Col, Form, Row} from 'react-bootstrap'
import {useInputDataState, useLabels} from '../../functions'
import {useSelector} from "react-redux";
import {FormLabel} from "../Utilities/Form";

export const OrderSummary = () => {
    const labels = useLabels()
    const plan = useSelector(state => state.customer.data.plan)
    const paymentOption = useSelector(state => {
        if (state.customer.data.paymentOption === 'U') {
            return 'Purchase'
        }
        return 'Lease'
    })
    const vip = useSelector(state => state.customer.data.voipSelection)
    const exp = useSelector(state => state.customer.data.expressSelection)
    const nor = useSelector(state => state.customer.data.nortonSelection)
    const mes = useSelector(state => state.customer.data.meshSelection)
    const dishBundleAccepted = useSelector(state => state.customer.data.dishBundleAccepted)
    const dish_subscriber_verified = useSelector(state => state.customer.data.dish_subscriber_verified)
    const selections = [vip, exp, nor, mes].filter(selection => !selectionIsEmpty(selection));
// Convert array to comma-separated string or 'None'
    const ops = selections.length > 0 ? selections.join(', ') : 'None';
    const credit_card_fail_count = useSelector(state => state.customer.data.credit_card_fail_count)
    const thirdPartyReasons = useSelector(state => {
        return state.settings.thirdPartyReasons
    })
    const [thirdPartyCc, setThirdPartyCc] = useInputDataState('thirdPartyCc')

    let opsComponent
    opsComponent = <Row className="mt-3 mb-5">
        <Col>
            <h5>{labels.orderSumOptionalSer} : {ops}</h5>
        </Col>
    </Row>

    let thirdPartyCcComponent = null
    if (credit_card_fail_count >= 1) {
        thirdPartyCcComponent = <Row className="mt-3 mb-5">
            <Col>
                <FormLabel required={true}>{labels.thirdPartyQuestion}</FormLabel>
                <Form.Select onChange={setThirdPartyCc} value={thirdPartyCc}>
                    <option></option>
                    {thirdPartyReasons.map(o => <option value={o} key={o}>{o}</option>)}
                </Form.Select>
            </Col>
        </Row>

    }

    return <>
        <Row className="mb-3 mt-5">
            <Col>
                <Alert>
                    <h3>{labels.orderSummary}</h3>
                </Alert>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <h5>{labels.orderSumPlanName} : {plan}</h5>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <h5>{labels.orderSumPayType} : {paymentOption}</h5>
            </Col>
        </Row>

        {opsComponent}

        {thirdPartyCcComponent}

        <Row className="mt-3">
            <Col>
                <h5>{dishBundleAccepted === 'Yes' && dish_subscriber_verified ?
                    labels.dishBundleIncluded
                    : null}</h5>
            </Col>
        </Row>

    </>
}


function selectionIsEmpty(value) {
    return value === null || value === undefined || value.trim() === '' || value === 'None';
}