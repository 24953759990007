import {Col, Form, Row} from 'react-bootstrap'
import {connect} from '../Notes/wrapper'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {useInputDataState, useLabels} from '../../functions'

export const ConsentToContact = () => {
    const labels = useLabels()
    const [contactConsent, setContactConsent] = useInputDataState('contactConsent')

    return <Row className="mt-3 mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true}>{labels.consentToContact}</FormLabel>
                <FormCheck label={labels.yesOption} type="radio" value="Y" onChange={setContactConsent}
                           checked={contactConsent === 'Y'}/>
                <FormCheck label="No" type="radio" value="N" onChange={setContactConsent}
                           checked={contactConsent === 'N'}/>
            </Form.Group>
        </Col>
    </Row>
}

export default connect(ConsentToContact, 'consent-to-contact')
