import {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {getDateString, useInputDataState, useLabels} from '../../functions'
import {getAvailableInstallDates, setAppLoadingState} from '../../state/settingsSlice'
import {cancelReservation, reserveInstallDate} from '../../state/customerAsyncActions'
import {setDataState, setInstallDate} from '../../state/customerSlice'
import {connect} from '../Notes/wrapper'
import {FormLabel, FormCheck} from '../Utilities/Form'
import './calendar.scss'

const TimeOption = (props) => {
    return <div className="time-option form-type-radio">
        <input type="radio" name="appointmentDate" value={props.dateTimeValue} className="form-radio"
               onClick={(e) => props.setSelectedDay(e.target.value)}/>
        <label>{props.time}</label>
    </div>
}

const getTimeOptions = (times, setSelectedDay) => {
    const options = times.map(t => <TimeOption key={t.time} {...t} setSelectedDay={setSelectedDay}/>)
    return <tr className="availableTimes inverted">
        <td colSpan="7">
            {options}
        </td>
    </tr>
}

const getParseAvailableTimes = (day, availableInstallDates) => {
    const key = getDateString(day)
    const days = availableInstallDates.filter(d => d.includes(key))
    const ret = []
    for (const d of days) {
        const parts = d.split(' ')
        ret.push({time: parts[1], dateTimeValue: d})
    }

    return ret
}


function getCalendar(inputDate, [selectedDay, setSelectedDay, onSelectedTime], availableInstallDates) {
    function getWeeks(date) {
        const cursorDate = new Date(date.getFullYear(), date.getMonth(), 1)
        const ret = []
        while (cursorDate.getMonth() === date.getMonth()) {
            ret.push(getWeek(cursorDate))
            let dayOfWeek = 7
            if (cursorDate.getDay() !== 0)
                dayOfWeek -= cursorDate.getDay()
            cursorDate.setDate(cursorDate.getDate() + dayOfWeek)
        }
        return ret
    }

    function getWeek(date) {
        const dayOfWeek = date.getDay()
        const ret = dayOfWeek !== 0 ? Array(dayOfWeek).fill(null) : []
        let dateCursor = new Date(date)
        for (let i = dayOfWeek; i < 7; i++) {
            ret.push(dateCursor)
            dateCursor = new Date(dateCursor)
            dateCursor.setDate(dateCursor.getDate() + 1)
        }

        return ret
    }

    function isDateAvailable(day) {
        if (!availableInstallDates) {
            return false
        }
        const key = getDateString(day)
        return availableInstallDates.find(d => d.includes(key))
    }

    function isDaySelected(day) {
        if (selectedDay == null || day == null) {
            return false
        }
        return selectedDay.getTime() === day.getTime()
    }


    const weeks = getWeeks(inputDate)
    const rows = []
    for (const week of weeks) {
        const columns = []
        let timeOptions
        for (const day of week) {
            if (day == null || inputDate.getMonth() !== day.getMonth()) {
                columns.push(<td
                    className="ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>)
            } else if (isDateAvailable(day)) {
                let className = 'ui-state-default'
                if (isDaySelected(day)) {
                    className += ' ui-state-active'
                    timeOptions = getTimeOptions(getParseAvailableTimes(day, availableInstallDates), onSelectedTime)
                }
                columns.push(<td className=" " onClick={() => setSelectedDay(day)}>
                    <p className={className}>{day.getDate()}</p>
                </td>)
            } else {
                columns.push(<td className="ui-datepicker-unselectable ui-state-disabled"><span
                    className="ui-state-default">{day.getDate()}</span></td>)
            }
        }
        rows.push(<tr>{columns}</tr>)
        if (timeOptions) {
            rows.push(timeOptions)
        }
    }
    return rows
}


function PrivateCalendar() {
    const caseId = useSelector(state => state.customer.data.caseId)
    const availableInstallDates = useSelector(state => state.settings.availableInstallDates)
    const [currentDate, setCurrentDate] = useState(new Date())
    const daySelection = useState(null)
    const dispatch = useDispatch()
    daySelection.push((s) => {
        dispatch(setInstallDate(s))
    })
    useEffect(() => {
        dispatch(getAvailableInstallDates(caseId))
        // eslint-disable-next-line
    }, [])

    if (availableInstallDates !== null
        && availableInstallDates.length === 1
        && (availableInstallDates[0] === 'Bypass' || availableInstallDates[0] === 'NA')
    ) {
        return <ul>
            <li>
                <span>You should get a call from your local installation company within 3 business days to schedule your order.</span>
            </li>
            <li>
                You should also receive a text message later today with your local installation company’s contact information if you have given permission for Hughes to send text messages.
            </li>

        </ul>
    }

    const rows = getCalendar(currentDate, daySelection, availableInstallDates)

    const setPreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
    }
    const setNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    return <>
        <Row className="mb-3">
            <Col sm={12} md={{span: 6, offset: 2}}>
                <div className="scheduling">
                    <div id="datepicker" className="hasDatepicker">
                        <div
                            className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
                            style={{display: 'block'}}>
                            <Row>
                                <Col md={{span: 8, offset: 3}}>
                                    <div
                                        className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
                                        <a className="ui-datepicker-prev ui-corner-all ui-state-disabled"
                                           title="Previous">
                                            <span onClick={setPreviousMonth} className="ui-icon"
                                                  id="previous-month" style={{cursor: 'pointer'}}>
                                                Previous
                                            </span>
                                        </a>
                                        <a className="ui-datepicker-next ui-corner-all link-2" title="Next">
                                            <span onClick={setNextMonth} className="ui-icon"
                                                  id="next-month"
                                                  style={{float: 'right', cursor: 'pointer'}}>
                                                Next
                                            </span>
                                        </a>
                                        <div className="ui-datepicker-title"><span className="ui-datepicker-month">
                                    {months[currentDate.getMonth()]}
                                </span>&nbsp;
                                            <span className="ui-datepicker-year"></span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={{span: 10, offset: 3}}>
                                    <table className="ui-datepicker-calendar">
                                        <thead>
                                        <tr>
                                            <th scope="col" className="ui-datepicker-week-end"><span
                                                title="Sunday">Su</span>
                                            </th>
                                            <th scope="col"><span title="Monday">Mo</span></th>
                                            <th scope="col"><span title="Tuesday">Tu</span></th>
                                            <th scope="col"><span title="Wednesday">We</span></th>
                                            <th scope="col"><span title="Thursday">Th</span></th>
                                            <th scope="col"><span title="Friday">Fr</span></th>
                                            <th scope="col" className="ui-datepicker-week-end"><span
                                                title="Saturday">Sa</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody id="calendar-tbody">
                                        {rows}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}

function DoReservation() {
    const labels = useLabels()
    const dispatch = useDispatch()

    const onClick1 = async () => {
        dispatch(setAppLoadingState('pending'))
        await dispatch(reserveInstallDate())
        dispatch(setAppLoadingState(''))
    }
    return <Row>
        <Col sm={12} md={{span: 8, offset: 2}}>
            <Row>
                <Col sm={12} md={5}>
                    <Button onClick={onClick1}>{labels.doReservation}</Button>
                </Col>
                <Col sm={12} md={{span: 5, offset: 1}}>
                    <Button onClick={() => dispatch(setInstallDate(''))}>{labels.resetInstallDate}</Button>
                </Col>
            </Row>
        </Col>
    </Row>
}

function ReservationMessage() {
    const labels = useLabels()
    const resId = useSelector(state => state.customer.data.resId)
    const installDate = useSelector(state => state.customer.data.installDate)
    const dispatch = useDispatch()

    const onClick = async () => {
        dispatch(setAppLoadingState('pending'))
        await dispatch(cancelReservation())
        dispatch(setAppLoadingState(''))
    }

    return <>
        <Row>
            <Col>
                <Alert variant="success">{labels.reservationPrefix}: {resId} <br/><b>{installDate}</b></Alert>
            </Col>
        </Row>
        <Row>
            <Col md={{offset: 5, span: 3}}>
                <Button onClick={onClick}>{labels.cancelReservation}</Button>
            </Col>
        </Row>
    </>
}

export const InstallerNotes = () => {
    const labels = useLabels()
    const [installerNotes, setInstallerNotes] = useInputDataState('installerNotes')

    return <Row className="mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true}>{labels.installerNotes}</FormLabel>
                <Form.Control as="textarea" onChange={setInstallerNotes} value={installerNotes}/>
            </Form.Group>
        </Col>
    </Row>
}


export const InstallationSchedule = () => {
    const labels = useLabels()
    const installDate = useSelector(state => state.customer.data.installDate)
    const reservationStatus = useSelector(state => state.customer.reservationStatus)
    const resId = useSelector(state => state.customer.data.resId)
    const plan = useSelector(state => state.customer.data.plan)
    const dispatch = useDispatch()

    if (!plan) {
        return <></>
    }

    let component
    if (resId || reservationStatus === 'done') {
        component = <ReservationMessage/>
    } else if (installDate && installDate !== 'Bypass') {
        component = <DoReservation/>
    } else if (installDate !== 'Bypass') {
        component = <PrivateCalendar/>
    }

    const onChange = (e) => {
        dispatch(setDataState({key: 'installDate', value: e.target.checked ? 'Bypass' : ''}))
    }

    return <>
        <Row>
            <Col>
                <Alert>
                    <h3>{labels.installationSchedule}</h3>
                    <h5>{labels.installationScheduleHelp}</h5>
                </Alert>
            </Col>
        </Row>
        {component}
        <InstallerNotes/>
        <Row>
            <Col>
                <Form.Group>
                    <FormCheck bold={true} label={labels.byPass} onChange={onChange}
                               checked={installDate === 'Bypass'}/>
                </Form.Group>
            </Col>
        </Row>
        <Row className="mb-3"></Row>
    </>
}

export default connect(InstallationSchedule, 'installation-schedule')
