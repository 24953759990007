import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Modal, Form, Button, Table} from 'react-bootstrap'
import {Barchart} from '../Icons'
import {getLeaderboards} from '../../state/customerAsyncActions'

export const LeaderBoard = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [leaderboard, setLeaderboard] = useState({})
    const [center, setCenter] = useState('')
    const leaderboards = useSelector(state => state.customer.leaderboards)
    const dispatch = useDispatch()

    const getLeaderboard = async (leaderboard, center) => {
        const response = await fetch(`${process.env.REACT_APP_CRM_NEW_BASE_URL}/api/v1/leaderboards/${leaderboard}/${center}`)
        if (response.ok) {
            setLeaderboard(await response.json())
        }
    }
    const onChangeCenter = (e) => {
        setCenter(e.target.value)
    }

    if (isOpen && leaderboards.length === 0) {
        dispatch(getLeaderboards())
    }

    const onHide = () => {
        setLeaderboard({})
        setIsOpen(false)
    }

    const getHeader = () => {
        return Object.keys(leaderboard.data[0].values)
    }

    const centers = ['Joplin', 'Springfield', 'Lincoln', 'Republic', 'Monterrey', 'Iterum', 'Edgemark', 'Mexico', 'Oklahoma', 'Emporia', 'icommsol']

    let component
    if (!leaderboard.data?.length) {
        component = <Modal
            size="lg"
            show={isOpen}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Center Selection
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="center-selection">
                        <label style={{paddingBottom: '10px'}}>Centers:</label>
                        <Form.Select onChange={onChangeCenter} value={center}>
                            <option></option>
                            {centers.map(c => <option value={c}>{c}</option>)}
                        </Form.Select>
                    </div>
                    <ul className="leaderboard">
                        {leaderboards.map(item => <li style={{paddingTop: '10px'}}>
                            <Button onClick={() => getLeaderboard(item.id, center)}>{item.name}</Button>
                        </li>)}
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    } else {
        const headers = getHeader()
        component = (<Modal size="lg"
                            show={isOpen}
                            onHide={onHide}
                            aria-labelledby="example-modal-sizes-title-lg">
            <Modal.Body>
                <Table className="l-table">
                    <thead>
                    <th>Agent</th>
                    {headers.map(h => <th>{h}</th>)}
                    </thead>
                    <tbody>
                    {leaderboard.data.map(item => (<tr>
                        <td>{item.agent}</td>
                        {headers.map(h => <td>{item.values[h]}</td>)}
                    </tr>))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>)
    }


    return <>
        <span onClick={() => setIsOpen(true)}>
            <Barchart/>
        </span>
        {component}
    </>
}