import {useSelector} from 'react-redux'
import AddressCollection from '../AddressCollection'
import Greetings from '../Greetings'
import {AllowCreditCheck, CheckBusinessAddress, EPQButton, EPQMessage1, EPQMessage2} from '../EPQ'
import {FormSetUp, Scripts} from '../Utilities/GeneralComponents'
import ProbingQuestions from '../ProbingQuestions'
import Plans from '../Plans'
import InstallationSchedule from '../InstallationSchedule'
import Cart from '../Cart'
import PreviewOrder from '../Cart/PreviewOrder'
import {getNote, isEPQCodeSuccessful, isMexico} from '../../functions'
import CreditCard from '../Cart/CreditCard'
import {Col, Row} from 'react-bootstrap'
import ConsentToContact from '../Cart/ConsentToContact'
import {MXPaymentInfo} from '../Cart/MXPaymentInfo'
import {PaymentSwitch} from '../Cart/MXPaymentMethod'
import PreOrderSection from '../Order/PreOrderSection'
import {DataViewer} from '../Utilities/DataViewer'
import DealerForm from './MXDealer'
import MXNoService from './MXNoService'
import {MultiDwellingUnit} from '../AddressCollection/MultiDwellingUnit'
import {getSectionContent} from '../Notes'

export function CustomerForm() {
    const orderType = useSelector(state => state.settings.orderType)

    let component = null
    if (orderType === 'new') {
        component = <PrivateCustomerForm/>
    } else if (orderType === 'existing') {
        component = <ExistingCustomer/>
    } else if (orderType === 'dealer') {
        component = <DealerForm/>
    }

    return (<>
        <Greetings/>
        {component}
    </>)
}

function ExistingCustomer() {
    const notes = useSelector(state => state.settings.notes)

    const note = getNote(notes, 'existing-customer')
    return <Row className="mt-3">
        <Col>
            {getSectionContent(note, {})}
        </Col>
    </Row>
}

// const useHelpProgram = () => {
//     const priorityPackage = useSelector(state => state.customer.data.priorityPackage)
//     const script = useSelector(state => state.customer.data.script)
//     const nybOffer = useSelector(state => state.customer.nybOffer)
//     const program = useSelector(state => state.customer.data.program)
//     if (isBusiness(script) || !priorityPackage || isMexico(program)) {
//         return null
//     }
//     if (nybOffer === 'No' || priorityPackage !== 'HNS Offer_01L_JU_NYCAF') {
//         return null //<ACP/>
//     }
//     return <NYB/>
// }

function PrivateCustomerForm() {
    const state = useSelector(state => ({
        settings: {
            successfulPimCodes: state.settings.successfulPimCodes
        }, customer: {
            data: {
                epqPim: state.customer.data.epqPim
            }
        }
    }))
    const priorityPackage = useSelector(state => state.customer.data.priorityPackage)
    const smePriorityPackage = useSelector(state => state.customer.data.smePriorityPackage)
    const epqPass = useSelector(state => state.customer.data.epqPass)
    const program = useSelector(state => state.customer.data.program)
    const cashOrCreditCard = useSelector(state => state.customer.data.cashOrCreditCard)
    const showOrderButton = useSelector(state => state.customer.showOrderButton)
    const mxNoServicePackages = useSelector(state => state.settings.mxNoServicePackages)

    const showCreditCheckButton = () => {
        return !isMexico(program) && (isEPQCodeSuccessful(state) || !!priorityPackage || !!smePriorityPackage)
    }


    const getShowOrderButton = () => {
        return showOrderButton || cashOrCreditCard
    }

    const isMXNoServicePackages = () => {
        return isMexico(program) && mxNoServicePackages.includes(priorityPackage)
    }

    return <>
        <FormSetUp/>
        <AddressCollection type="service" showTitle={true} showPhoneNumber={true}/>
        <Row className="mb-3">
            <Col><span style={{color: '#ff5820'}}>* Required</span></Col>
        </Row>
        <Scripts/>
        <EPQButton/>
        <EPQMessage1/>
        {isMXNoServicePackages() ? <MXNoService/> : null}

        <CheckBusinessAddress/>

        {epqPass !== '' && !isMXNoServicePackages() ? <ProbingQuestions/> : null}
        {showCreditCheckButton() ? <>
            <AllowCreditCheck/>
            <EPQMessage2/>
        </> : null}
        {epqPass !== '' && !isMXNoServicePackages() ? <>
            <Plans/>
            {!isMexico(program) ? <>
                <InstallationSchedule/>
                <Row className="mb-3"></Row>
                <MultiDwellingUnit/>
                <ConsentToContact/>
            </> : null}
            <Cart/>
            <PreviewOrder/>
            {isMexico(program) ? <MXPaymentInfo/> : null}
        </> : null}
        {isMexico(program) && !isMXNoServicePackages() ? <PaymentSwitch/> : <CreditCard/>}
        {getShowOrderButton() && !isMXNoServicePackages() ? <PreOrderSection/> : null}

        <DataViewer/>
    </>
}
