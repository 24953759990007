import {setNotesSection} from '../../state/settingsSlice'
import {getPlansVisualPage} from '../../state/customerAsyncActions'

export async function onAfterCaseLoad(dispatch, caseData) {
    dispatch(setNotesSection({script: caseData?.script, mediaType: caseData?.mediaType, section: 'greetings'}))
    if (caseData?.packages) {
        await dispatch(getPlansVisualPage())
    }
}

