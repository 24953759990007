import {useDispatch, useSelector} from 'react-redux'
import {useLabels} from '../../functions'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {FormLabel} from '../Utilities/Form'
import {checkDishSubscriber} from '../../state/customerAsyncActions'
import {validateDishSubscriber} from "../../state/validationSlice";
import {connect} from "../Notes/wrapper";
import {setDishSubscriber} from "../../state/customerSlice";

export const EligibleCustomer = () => {
    const labels = useLabels()
    const checkDishSubscriberState = useSelector(state => state.customer.checkDishSubscriberState)
    const dishSubscriber = useSelector(state => state.customer.data.dishSubscriber)
    const showDshPimMessage = useSelector(state => state.customer.showDshPimMessage)
    const dshPimMessage = useSelector(state => state.customer.data.dshmessage)
    const onDishSubscriber = (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        dispatch(setDishSubscriber(payload))
    }
    const dispatch = useDispatch()

    const onClick = async () => {
        const ret = await dispatch(validateDishSubscriber())
        if (ret.payload) {
            return
        }

        dispatch(checkDishSubscriber())
    }


    return <div>
        <Row className="mt-3">
            <Col md={5}>
                <FormLabel bold={true}>{labels.subscriberAcctNum}</FormLabel>
                <Form.Control value={dishSubscriber.accountNumber} as="input"
                              name="accountNumber"
                              onChange={onDishSubscriber}
                />
            </Col>
            <Col md={5}>
                <FormLabel bold={true}>{labels.subscriberLastName}</FormLabel>
                <Form.Control value={dishSubscriber.lastName} as="input"
                              name="lastName"
                              onChange={onDishSubscriber}
                />
            </Col>
        </Row>
        {showDshPimMessage ? <>
            <br/>
            <Row>
                <Col>
                    <p>{dshPimMessage}</p>
                </Col>
            </Row>
        </> : null}
        <Row className="mt-3 mb-3">
            <Col md={{span: 4, offset: 5}}>
                <Button onClick={onClick}>
                    {checkDishSubscriberState !== 'pending' ? labels.checkDishSubscriber : labels.checkingDishSubscriber}
                </Button>
            </Col>
        </Row>
    </div>
}


export default connect(EligibleCustomer, 'eligible-customer')


