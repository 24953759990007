import {Provider} from 'react-redux'
import store from './state/store'
import {AppLoader} from './components/Loader/AppLoader'
import {AppForEmbeddedPhone} from './components/Loader/AppForEmbeddedPhone'

function App() {
    const searchParams = new URLSearchParams(window.location.search)
    let AppLoaderComponent = AppLoader
    if (searchParams.has('embedded')) {
        AppLoaderComponent = AppForEmbeddedPhone
    }

    return (
        <Provider store={store}>
            <AppLoaderComponent/>
        </Provider>
    )
}

export default App
