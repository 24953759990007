import {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {BeatLoader} from 'react-spinners'
import {HughesnetLogo} from '../CustomerForm/HughesnetLogo'
import {Header} from '../Header'
import {Order} from '../Order'
import {useDispatch, useSelector} from 'react-redux'
import {AlertPopup} from '../Utilities/GeneralComponents'
import {getStringTranslations, login, setEmbeddedPhone, setShowLoginForm} from '../../state/settingsSlice'
import {CaseSelector, ErrorPage, LoginForm} from './Common'
import {getProfileInfo, handleDisposition, handleEndCallTask, handleNewCall,} from '../../state/embeddedSlice'
import {CustomerForm} from '../CustomerForm'
import {Notes} from '../Notes'


async function checkLogin(dispatch) {
    if (window.localStorage.creds) {
        const creds = JSON.parse(window.localStorage.creds)
        const ret = await dispatch(login({...creds}))
        const [ok] = ret.payload
        if (ok) {
            const ret = await dispatch(getProfileInfo())
            console.log(ret)
            if (!ret.payload) {
                dispatch(setShowLoginForm(true))
                return false
            }
            return ok
        }
    }
    dispatch(setShowLoginForm(true))
    return false
}

let appLoaded = false


export const AppForEmbeddedPhone = (props) => {
    const appLoadingState = useSelector(state => state.settings.appLoadingState)
    const showLoginForm = useSelector(state => state.settings.showLoginForm)
    const existingCases = useSelector(state => state.customer.cases)
    const applicationError = useSelector(state => state.customer.applicationError)
    const orderId = useSelector(state => state.customer.data.orderId)
    const caseId = useSelector(state => state.customer.data.caseId)
    const isAgentNoteOpen = useSelector(state => state.settings.isAgentNoteOpen)
    const embeddedShowNewCase = useSelector(state => state.embedded.embeddedShowNewCase)
    const args = useSelector(state => state.embedded.args)
    const alvariaUserId = useSelector(state => state.embedded.alvariaUserId)
    const dispatch = useDispatch()

    useEffect(() => {
        const init = async () => {
            if (!alvariaUserId) {
                await checkLogin(dispatch)
            }

            if (!appLoaded && alvariaUserId) {
                appLoaded = true
                dispatch(setEmbeddedPhone(true))
                const eventHandler = async (event) => {
                    const {data} = event
                    if (event.origin === process.env.REACT_APP_EMBEDDED_ALVARIA_ORIGIN || event.origin === process.env.REACT_APP_EMBEDDED_BRIDGE_ORIGIN) {
                        console.log('postMessage: ', event)
                        switch (data.eventName) {
                            case 'ACTIVE':
                                dispatch(handleNewCall(data))
                                break
                            case 'UDIdle':
                                dispatch(handleEndCallTask())
                                break
                            case 'IDLE':
                                dispatch(handleEndCallTask())
                                break
                            case 'DISPOSITION':
                                dispatch(handleDisposition(data))
                                break
                            default:
                                break
                        }
                    }
                }

                // window.addEventListener('message', eventHandler)

                function connectToEventBridge() {
                    const socket = new window.WebSocket(`wss://${process.env.REACT_APP_EMBEDDED_BRIDGE_ORIGIN}/ws/${alvariaUserId}`)
                    socket.onmessage = (message) => {
                        eventHandler(JSON.parse(message.data))
                    }

                    const handler = setInterval(() => {
                        socket.send('ping')
                    }, 60000)

                    socket.onclose = () => {
                        clearInterval(handler)
                        connectToEventBridge()
                    }

                }

                connectToEventBridge()

                const beforeUnloadHandler = (event) => {
                    // Recommended
                    event.preventDefault()

                    // Included for legacy support, e.g. Chrome/Edge < 119
                    event.returnValue = true
                }

                window.addEventListener('beforeunload', beforeUnloadHandler)

                dispatch(getStringTranslations())
            }
        }
        init()
    }, [alvariaUserId])


    function getComponent() {
        const onLogin = () => {
            dispatch(setShowLoginForm(false))
            dispatch(getProfileInfo())
        }

        if (showLoginForm) {
            return <LoginForm onLogin={onLogin}/>
        }


        if (existingCases.length > 0 || embeddedShowNewCase) {
            return <CaseSelector args={args}/>
        }

        let component
        if (applicationError) {
            component = <ErrorPage message={applicationError}/>
        } else if (orderId) {
            component = <Order/>
        } else if (caseId) {
            component = <CustomerForm/>
        } else {
            component = <HughesnetLogo/>
        }
        if (isAgentNoteOpen) {
            return <Row>
                <Col md={6} as="main" className="form-panel">{component}</Col>
                <Notes/>
            </Row>
        } else {
            return <Row as="main" className="form-panel">
                {component}
            </Row>
        }
    }


    const component = getComponent()

    return <>
        <div className="loading" style={{display: appLoadingState === 'pending' ? 'block' : ''}}>
            <div>
                <Row className="mt-3">
                    <Col md={{span: 2, offset: 5}}>
                        <div className="loading-container">
                            <BeatLoader color="#0092c5" size={40}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        {/*<WebPhone/>*/}
        <Header/>
        <Container className="main-container" fluid>
            {component}
            <AlertPopup/>
        </Container>
    </>
}