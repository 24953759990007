import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Col, Form, Row} from 'react-bootstrap'
import {
    isBusiness,
    isInputValid,
    isMexico,
    isProblemWithTheAddressPimCode,
    useInputDataState,
    useLabels
} from '../../functions'
import {setBillingAddress, setFirstName, setServiceAddress} from '../../state/customerSlice'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {connect} from '../Notes/wrapper'
import {Email} from '../Utilities/GeneralComponents'
import './address-collection.scss'

export const AddressCollection = (props) => {
    const isServiceAndBillingAddressTheSame = useSelector(state => state.customer.data.isServiceAndBillingAddressTheSame)
    const labels = useLabels()
    const script = useSelector(state => state.customer.data?.script?.toLowerCase())
    const program = useSelector(state => state.customer.data.program)
    const epqPim = useSelector(state => state.customer.data.epqPim)
    const orderPim = useSelector(state => state.customer.data.orderPim)
    const [privacyNoticeRead, setPrivacyNoticeRead] = useInputDataState('privacyNoticeRead')
    const dispatch = useDispatch()
    const firstName = useSelector(state => state.customer.data.firstName)


    let key = 'serviceAddress'

    function isBilling() {
        return props.type === 'billing'
    }

    if (isBilling()) {
        key = 'billingAddress'
    }
    const address = useSelector(state => state.customer.data[key])
    const addressValidation = useSelector(state => state.validation[key])
    const states = useSelector(state => {
        if (isMexico(state.customer.data.program)) {
            return state.settings.mxStates
        }
        return state.settings.usStates
    })

    const setAddress = (e) => {
        const name = e.target.name
        const payload = {[name]: e.target.value}
        if (name === 'overrideAddress') {
            payload[name] = e.target.checked ? 'true' : 'false'
        }

        if (isServiceAndBillingAddressTheSame) {
            dispatch(setBillingAddress(payload))
            dispatch(setServiceAddress(payload))
        } else if (isBilling()) {
            dispatch(setBillingAddress(payload))
        } else {
            dispatch(setServiceAddress(payload))
        }
    }

    const isFedexError = useSelector(state => state.customer.data.fedexError)

    useEffect(() => {
        if (isMexico(program)) {
            if (!address.address) {
                dispatch(setServiceAddress({ address: 'Casa 1' }));
                if (props.type === 'billing') {
                    dispatch(setBillingAddress({ address: 'Casa 1' }));
                }
            }
            if (!address.zip) {
                dispatch(setServiceAddress({ zip: '00000' }));
                if (props.type === 'billing') {
                    dispatch(setBillingAddress({ zip: '00000' }));
                }
            }
        }
    }, [dispatch, address.address, address.zip, program, props.type]);

    let overrideComponent
    if (props.showOverride !== false && (isFedexError || isProblemWithTheAddressPimCode(epqPim) || isProblemWithTheAddressPimCode(orderPim))) {
        overrideComponent = (
            <Form.Group>
                <FormCheck bold={true} type="checkbox" label={labels.addressOverride}
                           checked={address.overrideAddress === 'true'}
                           onChange={(e) => setAddress(e)} name="overrideAddress"/>
            </Form.Group>
        )
    }

    let phoneNumberField
    if (props.showPhoneNumber) {
        //This is the plain address component
        let altPhoneNumberField
        if (props.showAltPhoneNumber) {
            altPhoneNumberField = <Col md={6}>
                <Form.Group>
                    <FormLabel
                        required={false}>{isBilling() ? labels.billingPrefix : ''} {labels.addressALTPhoneNumber}</FormLabel>
                    <Form.Control value={address.altPhone} type="text" name="altPhone"
                                  placeholder="1112223333" onChange={setAddress}/>
                </Form.Group>
            </Col>
        }
        phoneNumberField = <>
            <Row className="mb-3">
                <Col md={6}>
                    <Form.Group>
                        <FormLabel required={true}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.addressPhoneNumber}</FormLabel>
                        <Form.Control isInvalid={!isInputValid(addressValidation, 'phoneNumber')}
                                      value={address.phoneNumber} type="text" name="phoneNumber"
                                      placeholder="1112223333" onChange={setAddress}/>
                        <Form.Control.Feedback type="invalid">{addressValidation.phoneNumber}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                {altPhoneNumberField}
            </Row>
        </>
    }

    let businessFields
    if (isBusiness(script) && props.showPhoneNumber) {
        businessFields = <>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <FormLabel required={!isMexico(program)}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.companyName}</FormLabel>
                        <Form.Control isInvalid={!isInputValid(addressValidation, 'companyName')}
                                      value={address.companyName} onChange={setAddress} name="companyName"/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Email/>
            </Row>
        </>
    }

    let mxAuthorization
    if (isMexico(program) && props.showPhoneNumber) {
        mxAuthorization = <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true}>{labels.privacyNoticeRead}</FormLabel>
                    <FormCheck type="radio" label="Si" value="Si" checked={privacyNoticeRead === 'Si'}
                               onChange={setPrivacyNoticeRead}/>
                    <FormCheck type="radio" label="No" value="No" checked={privacyNoticeRead === 'No'}
                               onChange={setPrivacyNoticeRead}/>
                </Form.Group>
            </Col>
        </Row>
    }

    return (
        <div className="address-collection">
            {props.showTitle ?
                <Row className="mb-3">
                    <Col>
                        <Alert>
                            <h3>{labels.addressCollection}</h3>
                            <h5>{labels.addressCollectionHelp}</h5>
                        </Alert>
                    </Col>
                </Row> : null
            }
            <Form>
                {phoneNumberField}
                {mxAuthorization}
                <Row className="mb-3">
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <FormLabel required={true}
                                       bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.addressFirstName}</FormLabel>
                            <Form.Control value={address.firstName} type="text" name="firstName" onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'firstName')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Group>
                            <FormLabel required={true}
                                       bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.addressLastName}</FormLabel>
                            <Form.Control value={address.lastName} type="text" name="lastName" onChange={setAddress}
                                          isInvalid={!isInputValid(addressValidation, 'lastName')}/>
                            <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                {businessFields}
                {!isMexico(program) || (isMexico && props.showFullAddress)?
                <Row className="mb-3">
                    <Col>
                        <FormLabel required={true}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.serviceAddress}</FormLabel>
                        <Form.Control value={address.address} type="text" name="address" onChange={setAddress}
                                      isInvalid={!isInputValid(addressValidation, 'address')}/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                </Row>:null}
                {!isMexico(program) || (isMexico && props.showFullAddress)?
                    <Row className="mb-3">
                    <Col>
                        <FormLabel
                            bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.serviceAddress2}</FormLabel>
                        <Form.Control value={address.address2} type="text" name="address2" onChange={setAddress}/>
                    </Col>
                </Row>:null}
                <Row className="mb-3">
                    <Col sm={12} md={4}>
                        <FormLabel required={true}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.city}</FormLabel>
                        <Form.Control value={address.city} type="text" name="city" onChange={setAddress}
                                      isInvalid={!isInputValid(addressValidation, 'city')}/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={4}>
                        <FormLabel required={true}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.state}</FormLabel>
                        <Form.Select name="state" value={address.state} onChange={setAddress}
                                     isInvalid={!isInputValid(addressValidation, 'state')}>
                            <option>Select state</option>
                            {states.map(s => <option key={s.code} value={s.code}>{s.name} ({s.code})</option>)}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>
                    {!isMexico(program) || (isMexico && props.showFullAddress)?
                    <Col sm={12} md={4}>
                        <FormLabel required={true}
                                   bold={true}>{isBilling() ? labels.billingPrefix : ''} {labels.zipCode}</FormLabel>
                        <Form.Control value={address.zip} type="text" name="zip" onChange={setAddress}
                                      isInvalid={!isInputValid(addressValidation, 'zip')}/>
                        <Form.Control.Feedback type="invalid">{labels.inputRequired}</Form.Control.Feedback>
                    </Col>:null}

                </Row>
            </Form>
            <Row className="mb-3">
                <Col>
                    {overrideComponent}
                </Col>
            </Row>
        </div>
    )
}

export default connect(AddressCollection, 'address-collection')
