import {Alert, Col, Form, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useInputDataState, useLabels} from '../../functions'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {setServiceAddress} from '../../state/customerSlice'

export const MXPaymentInfo = () => {
    const labels = useLabels()
    const mxIdTypes = useSelector(state => state.settings.mxIdTypes)
    const [documentType, setDocumentType] = useInputDataState('documentType')
    const [documentNumber, setDocumentNumber] = useInputDataState('documentNumber')
    const [documentIssueDate, setDocumentIssueDate] = useInputDataState('documentIssueDate')
    const [documentExpireDate, setDocumentExpireDate] = useInputDataState('documentExpireDate')
    const [processedAs, setProcessedAs] = useInputDataState('processedAs')
    const [taxId, setTaxId] = useInputDataState('taxId')
    const dispatch = useDispatch()
    const address = useSelector(state => state.customer.data.serviceAddress)

    const setAddress = (e) => {
        const payload = {'companyName': e.target.value}
        dispatch(setServiceAddress(payload))
    }

    const personTypes = ['Persona Fisica (individual)', 'Persona Moral (company)']

    return <>
        <Row>
            <Col>
                <Alert>
                    <h3>
                        {labels.documentInfo}
                    </h3>
                </Alert>
            </Col>
        </Row>
        <Row className="mt-3 mb-3">
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.documentType}</FormLabel>
                    <Form.Select value={documentType} onChange={setDocumentType}>
                        <option></option>
                        {mxIdTypes.map(item => <option key={item.code} value={item.code}>{item.name}</option>)}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.documentNumber}</FormLabel>
                    <Form.Control value={documentNumber} onChange={setDocumentNumber}/>
                </Form.Group>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.documentDateIssue}</FormLabel>
                    <Form.Control value={documentIssueDate} onChange={setDocumentIssueDate}/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.documentExpirationDate}</FormLabel>
                    <Form.Control value={documentExpireDate} onChange={setDocumentExpireDate}/>
                </Form.Group>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel required={true} bold={true}>{labels.individualOrCompany}</FormLabel>
                    {personTypes.map(e =>
                        <FormCheck onChange={setProcessedAs} type="radio" key={e} value={e} checked={processedAs === e} label={e}/>)}
                </Form.Group>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.companyName}</FormLabel>
                    <Form.Control value={address.companyName} onChange={setAddress} name="companyName"/>
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.taxId}</FormLabel>
                    <Form.Control placeholder="Ejemplo 12-13 caracteres, todos en mayúsculas:  LOZG7502123T7 "
                                  value={taxId} onChange={setTaxId}/>
                </Form.Group>
            </Col>
        </Row>
    </>
}
