import {Button, Col, Row} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux'
import {setAppLoadingState} from '../../state/settingsSlice'
import {processOrder} from '../../state/customerAsyncActions'
import {isMexico, useLabels} from '../../functions'
import {openAlert} from '../../state/customerSlice'

export const ProcessOrderButton = () => {
    const labels = useLabels()
    const {chargesExplained, taxId, program, reasonNotCC, tcEmail} = useSelector(state => {
        const {chargesExplained, taxId, program, reasonNotCC, tcEmail, acpLearnMore, script} = state.customer.data
        return {chargesExplained, taxId, program, reasonNotCC, tcEmail, acpLearnMore, script}
    })
    const dispatch = useDispatch()

    let key = 'serviceAddress'
    const address = useSelector(state => state.customer.data[key])

    const credit_card_lockout = useSelector(state => state.customer.data.credit_card_lockout)

    const isMXTaxIdValid = () => {
        if (!isMexico(program) || !taxId) {
            return true
        }

        const regex = /^[A-Za-z]{3,4}[ |\-]{0,1}[0-9]{6}[ |\-]{0,1}[0-9A-Za-z]{3}$/
        return regex.test(taxId)
    }

    const isMXZipValid = () => {
        if (!isMexico(program)) {
            return true
        }
        const regex = /^\d{5}$/
        return regex.test(address.zip)
    }

    const onClickProcessOrder = async () => {
        if (chargesExplained !== 'Yes' && chargesExplained !== 'No') {
            dispatch(openAlert(labels.explainTsAndCs))
            return
        }
        // if (credit_card_fail_count >= 1 && isEmpty(thirdPartyCc)) {
        //     dispatch(openAlert(labels.thirdPartyCcRequired))
        //     return
        // }
        if (credit_card_lockout) {
            dispatch(openAlert(labels.creditCardLockout))
            return
        }
        if (!isMXTaxIdValid()) {
            dispatch(openAlert(labels.taxIdInvalid))
            return
        }

        if (!isMXZipValid()) {
            dispatch(openAlert(labels.mxZipInvalid))
            return
        }

        if (isMexico(program) && address.address === 'Casa 1') {
            dispatch(openAlert(`${labels.fieldIsRequied} "${labels.serviceAddress}"`))
            return
        }

        // if (isMexico(program) && address.zip === '00000') {
        //     dispatch(openAlert(`${labels.fieldIsRequied} "${labels.zipCode}"`))
        //     return
        // }

        if (isMexico(program) && !reasonNotCC) {
            dispatch(openAlert(`${labels.fieldIsRequied} "${labels.whyCash}"`))
            return
        }

        if (tcEmail && tcEmail !== 'nobody@hughes.com' && tcEmail.substring(0, 2) === 'no') {
            dispatch(openAlert(labels.invalidEmailAddress))
            return
        }

        dispatch(setAppLoadingState('pending'))
        await dispatch(processOrder())
        dispatch(setAppLoadingState(''))
    }

    return <>
        <Row className="mb-3 mt-5">
            <Col md={{span: 3, offset: 5}}>
                <Button onClick={onClickProcessOrder}>
                    {labels.processOrder}
                </Button>
            </Col>
        </Row>
    </>
}
