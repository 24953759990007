import {useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {DirectTV} from './DirectTV'
import {Dish} from './Dish'
import {scriptFrontier} from '../../constants'
import {Frontier} from './Frontier'

export const Transfer = () => {
    const isDTV = useSelector(state => state.customer.data.isDTV)
    const script = useSelector(state => state.customer.data.script)

    let component
    if (script === scriptFrontier) {
        component = <Frontier/>
    } else if (isDTV === true) {
        component = <DirectTV/>
    }
    else if (isDTV === false) {
        component = <Dish/>
    }

    return <Row className="mt-5">
        <Col>{component}</Col>
    </Row>
}
