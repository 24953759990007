import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Col, Form, Row} from 'react-bootstrap'
import {getBRTMessage, isBusiness, isMexico, useInputDataState, useIsPR, useLabels} from '../../functions'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {checkBRT} from '../../state/customerAsyncActions'
import {setAppLoadingState} from '../../state/settingsSlice'
import {FlexOffer} from './FlexOffer'


export const OptionalServices = () => {
    const mesh = useSelector(state => state.customer.optionalMesh)
    const voice = useSelector(state => state.customer.optionalVoice)
    const repair = useSelector(state => state.customer.optionalRepair)
    const norton = useSelector(state => state.customer.optionalNorton)
    const staticIP = useSelector(state => state.customer.optionalStaticIP)
    const script = useSelector(state => state.customer.data?.script?.toLowerCase())
    const [meshSelection, setMeshSelection] = useInputDataState('meshSelection')
    const [voipSelection, setVoipSelection] = useInputDataState('voipSelection')
    const [expressSelection, setExpressSelection] = useInputDataState('expressSelection')
    const [nortonSelection, setNortonSelection] = useInputDataState('nortonSelection')
    const [staticIPSelection, setStaticIPSelection] = useInputDataState('staticIPSelection')
    const [landLinePhone, setLandLinePhone] = useInputDataState('landLinePhone')
    const labels = useLabels()
    const epqPass = useSelector(state => state.customer.data.epqPass)
    const lease = useSelector(state => state.customer.data.lease)
    const brtMessageCode = useSelector(state => state.customer.data.brtMessageCode)
    const brtApiError = useSelector(state => state.customer.data.brtApiError)
    const dispatch = useDispatch()
    const [explainedPricing, setExplainPricing] = useInputDataState('explainedPricing')
    const isPR = useIsPR()
    const program = useSelector(state => state.customer.data.program)


    const localCheckBRT = async () => {
        dispatch(setAppLoadingState('pending'))
        await dispatch(checkBRT())
        dispatch(setAppLoadingState(''))
    }

    let voiceField
    if (voice.length > 0) {
        const voiceOptions = voice.map(v => (
            <FormCheck disabled={epqPass !== '2' && lease === 'Y'} key={v.value} name="voice"
                       checked={voipSelection === v.value}
                       label={v.label} value={v.value} onChange={setVoipSelection}/>))
        voiceField = <Row className="mb-3">
            <Col>
                <FormLabel bold={true}>
                    <span>{labels.voiceOptionHelp}</span> <br/>
                    {labels.voiceOption}
                </FormLabel>
                {voiceOptions}
            </Col>
        </Row>
    }


    //
    let repairField
    if (repair.length > 0 && !isPR) {
        const repairOptions = repair.map(r => (
            <FormCheck key={r.label} name="expressSelection" checked={expressSelection === r.value} label={r.label}
                       value={r.value}
                       onChange={setExpressSelection} disabled={epqPass !== '2' && lease === 'Y'}/>))
        repairField = <>
            <Row className="mb-3">
                <Col>
                    <FormLabel bold={true}>
                        <span>{labels.expressRepairOptionHelp}</span> <br/>
                        {labels.expressRepairOption}
                    </FormLabel>
                    {repairOptions}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <FormLabel bold={true} required={true}>{labels.explainedPricing}</FormLabel>
                        <FormCheck type="radio" value={"Yes"} checked={explainedPricing === 'Yes'}
                                   onChange={setExplainPricing} label={labels.yesOption}/>
                        <FormCheck type="radio" value={"No"} checked={explainedPricing === 'No'}
                                   onChange={setExplainPricing} label="No"/>
                    </Form.Group>
                </Col>
            </Row>
        </>
    }

    let nortonField
    if (norton.length > 0) {
        const nortonOptions = norton.map(n => (
            <FormCheck onChange={setNortonSelection} name="norton" checked={n.value === nortonSelection}
                       value={n.value}
                       label={n.label} disabled={epqPass !== '2' && lease === 'Y'} key={n.label}/>))
        nortonField = (<Row className="mb-3">
            <Col>
                <FormLabel bold={true}>
                    <span>{labels.nortonOptionHelp}</span> <br/>
                    {labels.nortonOption}
                </FormLabel>
                {nortonOptions}
            </Col>
        </Row>)
    }

    let meshField
    if (mesh.length > 1 && !isPR && !isMexico(program)) {
        let mesh_label;
        if (isBusiness(script)) {
            mesh_label = labels.meshNetwork
        } else {
            mesh_label = labels.meshNetworkHome
        }

        const meshOptions = mesh.map(m => <FormCheck name="meshSelection"
                                                             onChange={setMeshSelection} value={m.value}
                                                             key={m.label}
                                                             checked={meshSelection === m.value} label={m.label}/>)
        meshField = <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true}>{mesh_label}</FormLabel>
                    {meshOptions}
                </Form.Group>
            </Col>
        </Row>

    }

    let staticIPField
    if (isBusiness(script) && staticIP.length) {
        const staticIPOptions = staticIP.map(s => <FormCheck name="staticIPSelection"
                                                             onChange={setStaticIPSelection} value={s.value}
                                                             key={s.label}
                                                             checked={staticIPSelection === s.value} label={s.label}/>)
        staticIPField = <Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormLabel bold={true}>{labels.staticIPOptional}</FormLabel>
                    {staticIPOptions}
                </Form.Group>
            </Col>
        </Row>
    }

    let brtComponent
    if (brtMessageCode || brtApiError) {
        brtComponent = <Row className="mb-3">
            <Col>
                <Alert variant={brtMessageCode ? 'primary' : 'danger'}>
                    {brtMessageCode ? getBRTMessage(brtMessageCode, labels) : brtApiError}
                </Alert>
            </Col>
        </Row>
    }


    return <>
        {voiceField}
        {meshField}
        {repairField}
        {nortonField}
        {staticIPField}
        <Row className="mb-3">
            <Col md={5}>
                <Form.Group>
                    <FormLabel bold={true}>{labels.landLinePhone}</FormLabel>
                    <Form.Control value={landLinePhone} onChange={setLandLinePhone}/>
                </Form.Group>
            </Col>
            <Col md={{span: 4}}>
                <div style={{marginTop: '32px'}}>
                    <Button onClick={localCheckBRT}>{labels.checkBRT}</Button>
                </div>
            </Col>
        </Row>
        {brtComponent}
        <FlexOffer/>
    </>
}
