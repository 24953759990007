import {useSelector} from 'react-redux'
import {Alert, Col, Row} from 'react-bootstrap'

export const OrderMessage = () => {
    const orderPim = useSelector(state => state.customer.data.orderPim)
    const orderMessage = useSelector(state => state.customer.data.orderMessage)

    if (orderPim && orderPim !== '5') {
        return <>
            <Row className="mt-3 mb-5">
                <Col>
                    <h4>Order Pim Code: {orderPim}</h4>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Alert variant="danger">{orderMessage}</Alert>
                </Col>
            </Row>
        </>
    }

    return <></>
}
