import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const MaskedInput = ({ value, onChange, type = 'text', placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);

    const maskValue = (val) => {
        if (!val) return '';
        if (!isFocused) {
            return '•'.repeat(val.length);
        }
        return val;
    };

    return (
        <Form.Control
            type={type}
            value={isFocused ? value : maskValue(value)}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={placeholder}
            autoComplete="off"
            data-private="true"
        />
    );
};

export default MaskedInput;