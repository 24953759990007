import {useInputDataState} from '../../functions'
import {EligibleCustomer} from './EligibleCustomer'
import {connect} from '../Notes/wrapper'


export const DishBundle = () => {
    const [dishBundleMentioned] = useInputDataState('dishBundleMentioned')

    let components
    if (dishBundleMentioned === 'Yes') {
        components = <>
            <EligibleCustomer/>
        </>
    }
    return <>
        {components}
    </>
}

export default connect(DishBundle, 'dish-bundle')
