import {useSelector} from 'react-redux'
import {connect} from '../Notes/wrapper'
import {ProbingQuestionsConsumer} from './ProbingQuestionsConsumer'
import {ProbingQuestionsBusiness} from './ProbingQuestionsBusiness'
import {isBusiness, isConsumer, isMexico} from '../../functions'

export const ProbingQuestions = () => {
    const script = useSelector(state => state.customer.data.script)
    const program = useSelector(state => state.customer.data.program)

    if (isConsumer(script) || isMexico(program)) {
        return <ProbingQuestionsConsumer/>
    }

    if (isBusiness(script)) {
        return <ProbingQuestionsBusiness/>
    }

    return <></>
}

export default connect(ProbingQuestions, 'probing-questions')
