import {Col, Row} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {isEPQCodeSuccessful, isMexico, useLabels} from '../../functions'

export const Sections = () => {
    const labels = useLabels()
    const epqPass = useSelector(state => state.customer.data.epqPass)
    const showOrderButton = useSelector(state => state.customer.showOrderButton)
    const state = useSelector(state => ({
        settings: {
            successfulPimCodes: state.settings.successfulPimCodes
        },
        customer: {
            data: {
                epqPim: state.customer.data.epqPim
            }
        }
    }))
    const plans = useSelector(state => state.customer.plans)
    const cartState = useSelector(state => state.customer.cartState)
    const orderId = useSelector(state => state.customer.data.orderId)
    const program = useSelector(state => state.customer.data.program)

    if (orderId) {
        return <></>
    }

    return <Row className="sections">
        <Col>
            <a href="#address-collection">{labels.headerAddress}</a>
            {!isMexico(program) ? <a href="#credit-check">{labels.headerCreditCheck}</a> : null}
            {epqPass !== '' ? <a href="#probing-questions">{labels.headerProbingQuestions}</a> : null}
            {isEPQCodeSuccessful(state) && plans && plans.length > 0 ? <a href="#plans">{labels.headerPlans}</a> : null}
            {cartState === 'done' ? <a href="#cart">{labels.headerCart}</a> : null}
            {showOrderButton ? <>
                    <a href="#review-contact-information">{labels.headerReviewContactInformation}</a>
                    <a href="#pre-order">{labels.headerProcessOrder}</a>
                </>
                : null
            }
        </Col>
    </Row>
}
