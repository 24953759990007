import {Button, Col, Row} from "react-bootstrap";
import {useDispatch} from 'react-redux'
import {setAppLoadingState} from '../../state/settingsSlice'
import {cancelOrder} from '../../state/customerAsyncActions'
import {useLabels} from '../../functions'

export const ProcessCancelOrderButton = () => {
    const labels = useLabels()

    const dispatch = useDispatch()

    const onClickProcessCancelOrder = async () => {

        dispatch(setAppLoadingState('pending'))
        await dispatch(cancelOrder())
        dispatch(setAppLoadingState(''))
    }

    return <>
        <Row className="mb-3 mt-5">
            <Col md={{span: 3, offset: 5}}>
                <Button onClick={onClickProcessCancelOrder}>
                    {labels.cancelOrder}
                </Button>
            </Col>
        </Row>
    </>
}
