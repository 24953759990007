import {AddressCollection} from './index'
import {BillingAddressCollection} from '../BillingAddressCollection'
import {connect} from '../Notes/wrapper'
import {Alert, Col, Row} from 'react-bootstrap'
import {useLabels} from '../../functions'
import {InstallerNotes} from '../InstallationSchedule'
import {Email} from '../Utilities/GeneralComponents'

export const ReviewContactInfo = () => {
    const labels = useLabels()

    return <>
        <Row className="mb-3 mt-5">
            <Col>
                <Alert>
                    <h3>{labels.customerInformationRecap}</h3>
                    <h5>{labels.customerInformationRecapHelp}</h5>
                </Alert>
            </Col>
        </Row>
        <AddressCollection showTitle={false} type="service" showPhoneNumber={true} showAltPhoneNumber={true} showFullAddress={true}/>
        <Row className="mb-3">
            <Email/>
        </Row>
        <BillingAddressCollection/>
        <InstallerNotes/>
    </>
}


export default connect(ReviewContactInfo, 'review-contact-information')
