import {useSelector} from 'react-redux'
import {getNote} from '../../functions'
import {getSectionContent} from '../Notes'

export const TermsAndConditions = () => {
    const chargesExplained = useSelector(state => state.customer.data.chargesExplained)
    const notes = useSelector(state => state.settings.notes)
    const lease = useSelector(state => state.customer.data.lease)
    const data = useSelector(state => state.customer.data)

    let component
    if (chargesExplained === 'No') {
        let note
        if (lease === 'N') {
            note = getNote(notes, 'purchase-terms')
        } else {
            note = getNote(notes, 'lease-terms')
        }

        if (note) {
            component = getSectionContent(note, data)
        }
    }

    return <div className="wtf">
        {component}
    </div>
}
