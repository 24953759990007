import {createAsyncThunk} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {programMexico, scriptBusiness, scriptFrontierWholesale, scriptZiply, urlHost} from '../constants'
import {getCredentialsHeader, isBusiness, isConsumer, isMexico, isProblemWithTheAddressPimCode} from '../functions'


const _fetchCase = async ({caseId, program}, state) => {
    const response = await fetch(`${urlHost}/srv_calldata/new/getCaseById.php?caseid=${caseId}&program=${program}`, {
        headers: {...getCredentialsHeader(state)},
    })
    if (response.ok) {
        return response.json()
    }
}

export const fetchCase = createAsyncThunk('customer/fetchCase', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    return _fetchCase(arg, state)
})

export const fetchCCInfo = createAsyncThunk('customer/fetchCCInfo', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = await _fetchCase(arg, state)
    const {cashOrCreditCard, creditCard} = data
    if (creditCard.creditNumber) {
        return {
            cashOrCreditCard, creditCard,
        }
    }
})

export const getLastUpdate = async (data, credentials) => {
    const body = JSON.stringify({caseid: data.caseId})
    let path = 'srv_calldata/getLastUpdate_tfnMediaaniMatch.php'
    if (isMexico(data.program)) {
        path = 'srv_calldata/getLastUpdate_tfnMediaaniMatch_MX.php'
    }
    const res = await fetch(`${urlHost}/${path}?format=new`, {
        headers: {...credentials, 'Content-Type': 'application'}, method: 'post', body,
    })

    if (res.ok) {
        return res.json()
    }

    return data
}

export const createNewCase = createAsyncThunk('customer/createNewCase', async (args, thunkAPI) => {
    let path = 'srv_calldata/getNewCaseID_C.php'
    if (isMexico(args.program)) {
        path = 'srv_calldata/getNewCaseID_MX.php'
    }
    const credentials = getCredentialsHeader(thunkAPI.getState())
    const response = await fetch(`${urlHost}/${path}?format=new`, {
        method: 'post', headers: {...credentials, 'Content-Type': 'application/json'}, body: JSON.stringify(args),
    })
    if (response.status !== 200) {
        if (response.status === 403) {
            Sentry.captureMessage(`Authentication error: ${JSON.stringify(credentials)}`, 'fatal')
        } else {
            Sentry.captureMessage(`error when creating case. Query: ${window.location.href}`, 'fatal')
        }
        throw new Error(response.statusMessage)
    }
    const data = await response.json()
    return getLastUpdate(data, credentials)
})

export const reAssignCase = createAsyncThunk('customer/reAssignCase', async (input, thunkAPI) => {
    const {args, data} = input
    const state = thunkAPI.getState()
    let {payload: data2} = await thunkAPI.dispatch(createNewCase(args))

    const init = ['howDidYouHear', 'connection', 'users', 'serviceAddress', 'billingAddress', 'tcPhone', 'continuity', 'dob', 'ssn',]
    const copy = {}
    for (const key in data) {
        if (init.indexOf(key) !== -1) {
            copy[key] = data[key]
        }
    }
    const ret = {...copy, ...data2}

    await doWriteToRedis(JSON.stringify(ret), state)

    return ret
})

export const checkExistingCases = createAsyncThunk('customer/checkExistingCases', async (arg, thunkAPI) => {
    const response = await fetch(`${urlHost}/srv_calldata/new/getExistingCases.php`, {
        method: 'post',
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'},
        body: JSON.stringify({ani: arg.ani, taskSid: arg.umid, program: arg.program,}),
    })

    return response.json()
})

async function doWriteToRedis(body, state) {
    const response = await fetch(`${urlHost}/srv_calldata/new/writeToRedis.php`, {
        method: 'post', body, headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
    })
    if (response.status !== 200) {
        throw new Error(response.statusMessage)
    }
}

export const writeToRedis = createAsyncThunk('customer/writeToRedis', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const body = JSON.stringify(state.customer.data)
    await doWriteToRedis(body, state)
})

const fixAddressWithFedexAPI = async (caseId, state) => {
    const response = await fetch(`${urlHost}/srv_fedex/epqfedexcheck.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    })
    return response.json()
}

export const getPlansVisualPage = createAsyncThunk('customer/getPlansVisualPage', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    let path = 'srv_calldata/getPlansVisualPage_catalog.php'
    if (isMexico(state.customer.data.program)) {
        path = 'srv_calldata/plansOffers.php'
    } else if (isBusiness(state.customer.data.script)) {
        path = 'srv_calldata/getPlansVisualPageBusiness_catalog.php'
    }

    const {caseId, program} = state.customer.data
    const response = await fetch(`${urlHost}/${path}`, {
        method: 'post',
        body: JSON.stringify({caseid: caseId}),
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application'}
    })

    thunkAPI.dispatch(fetchCase({caseId, program}))

    return response.json()
})
export const setCheckBusinessAddress = createAsyncThunk('customer/checkBusinessAddress', (arg, thunkAPI) => {
    return new Promise((resolve) => {
        resolve(arg)
    })
})

export const setLKP = createAsyncThunk('customer/setLKP', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const body = JSON.stringify({caseid: state.customer.data.caseId})
    const response = await fetch(`${urlHost}/srv_calldata/new/setLKP.php`, {
        method: 'post', body, headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
    })

    if (response.ok) {
        return response.json()
    }
})

const checkAcpOffers = async (state) => {
    const data = state.customer.data
    const response = await fetch(`${urlHost}/srv_calldata/setEbbEligibility.php?format=new`, {
        method: 'post',
        body: JSON.stringify({caseid: data.caseId}),
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
    })

    return response.json()
}

const sendLeadToSaleForce = createAsyncThunk('thirdParty/salesForceLeade', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const {script, caseId, program} = state.customer.data
    if (!caseId || !script) {
        return
    }
    if (isMexico(program) || !isBusiness(script) || state.customer.leadSentToSaleForce) {
        return
    }

    const ret = await fetch(`${urlHost}/srv_salesforce/webformpost.php`, {
        method: 'post',
        body: JSON.stringify({caseid: caseId}),
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
    })

    if (ret.ok) {
        return ret.json()
    }
})

export const runEPQ = createAsyncThunk('customer/runEPQ', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const epqPass = arg
    let customerData = {...state.customer.data, fedexError: false, fedexOverride: '', epqPass}

    let body
    if (isMexico(customerData.program)) {
        await thunkAPI.dispatch(getGeoCode(epqPass))
    } else {
        body = JSON.stringify(customerData)
        await doWriteToRedis(body, state)
    }

    const caseId = customerData.caseId
    body = JSON.stringify({caseid: caseId})

    let response = await doRunEPQ()

    let data = await response.json()
    //Problem with the address. Let's try to fix it using fedex WS
    if (isProblemWithTheAddressPimCode(data.epqPim)) {
        const serverData = await fixAddressWithFedexAPI(caseId, state)
        if (serverData.fedexOverride === 'Fedex standardized address') {
            response = await doRunEPQ()
            data = await response.json()
        }
    }

    const {epqType, script, consumerException} = data
    const s = script?.toLowerCase()
    if (!consumerException && epqType?.toLowerCase() === scriptBusiness && isConsumer(s) && ![scriptZiply, scriptFrontierWholesale].includes(s)) {
        await thunkAPI.dispatch(setCheckBusinessAddress(true))
    }

    const isDTV = await thunkAPI.dispatch(checkDTVZip())
    data.isDTV = isDTV.payload

    if (data.acpLearnMore === 'Yes') {
        data = await checkAcpOffers(state)
    }

    thunkAPI.dispatch(getPlansVisualPage())
    thunkAPI.dispatch(sendLeadToSaleForce())

    return data

    async function doRunEPQ() {
        let url = 'srv_hughes/epq_openapi.php'
        if (isMexico(customerData.program)) {
            url = 'srv_hughes/epq_mx_openapi.php'
        }
        const response = await fetch(`${urlHost}/${url}`, {
            method: 'post', body, headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
        })
        if (response.status !== 200) {
            throw new Error(response.statusMessage)
        }
        return response
    }
})


export const initBusinessFromConsumer = createAsyncThunk('customer/initBusinessFromConsumer', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const res = await fetch(`${urlHost}/srv_calldata/initBusinessFromConsumer.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application'},
        method: 'post',
        body: JSON.stringify({caseid: state.customer.data.caseId}),
    })

    if (state.customer.data.epqPass !== '') {
        thunkAPI.dispatch(runEPQ('1'))
    }

    if (res.ok) {
        return res.json()
    }
})

export const writeExtraChoices = createAsyncThunk('customer/extraChoices', async (arg, thunkAPI) => {
    const data = thunkAPI.getState().customer.data
    const body = JSON.stringify(data)
    const response = await fetch(`${urlHost}/srv_calldata/writeToDB_extras_epq1_choices.php?format=new`, {
        method: 'post',
        body,
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'}
    })

    return response.json()
})

export const writeExtras = createAsyncThunk('customer/extras', async ({varName, varValue}, thunkAPI) => {
    const data = thunkAPI.getState().customer.data
    const response = await fetch(`${urlHost}/srv_calldata/writeToDB_extras.php?format=new`, {
        method: 'post',
        body: JSON.stringify({var_name: varName, var_value: varValue, caseid: data.caseId}),
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'}
    })

    return response.json()
})

export const reserveInstallDate = createAsyncThunk('customer/reserveInstallDate', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const body = JSON.stringify(data)
    await doWriteToRedis(body, state)
    const response = await fetch(`${urlHost}/srv_hughes/set_schedule.php?format=new`, {
        method: 'post',
        body: JSON.stringify({caseid: data.caseId}),
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}
    })

    return response.json()
})

export const getTotalCalculations = createAsyncThunk('customer/getTotalCalculations', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    await doWriteToRedis(JSON.stringify(data), state)
    let path = 'srv_calldata/getTotalCalculationSeparateSections_catalog.php'
    if (data.program === programMexico) {
        path = 'srv_calldata/getTotalCalculationSeparateSections.php'
    }

    const response = await fetch(`${urlHost}/${path}?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: state.customer.data.caseId}),
        method: 'post'
    })

    return response.json()
})

export const processOrder = createAsyncThunk('customer/processOrder', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const body = JSON.stringify(data)
    await doWriteToRedis(body, state)
    const {caseId, program} = data
    const init = {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    }
    let path = 'srv_hughes/ord_openapi.php'
    if (isMexico(program)) {
        path = 'srv_hughes/ord_mx_openapi.php'
    }
    const response = await fetch(`${urlHost}/${path}`, init)
    return response.json()
})

export const cancelOrder = createAsyncThunk('customer/cancelOrder', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const body = JSON.stringify(data)
    await doWriteToRedis(body, state)
    const {caseId} = data
    const init = {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    }
    let path = 'srv_hughes/can_openapi.php'
    const response = await fetch(`${urlHost}/${path}`, init)
    return response.json()
})

export const validateEmail = createAsyncThunk('customer/validateEmail', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const body = JSON.stringify(data)
    await doWriteToRedis(body, state)
    const {caseId} = data
    const init = {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    }
    let path = 'srv_hughes/vem_openapi.php'
    const response = await fetch(`${urlHost}/${path}`, init)

    return response.json()
})


export const checkDTVZip = createAsyncThunk('consumer/checkDTVZip', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const init = {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post'
    }

    const response2 = await fetch(`${urlHost}/srv_calldata/check_dtv_zip.php`, init)
    const ret = await response2.json()
    return ret
})

export const foreNYB = createAsyncThunk('customer/force_nybb', async (arg, thunkAPI) => {
    const data = thunkAPI.getState().customer.data
    const response = await fetch(`${urlHost}/srv_calldata/force_nybb.php?format=new`, {
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post'
    })

    return response.json()
})

export const sendPreviewOrder = createAsyncThunk('customer/sendPreviewOrder', async (arg, thunkAPI) => {
    await thunkAPI.dispatch(writeToRedis())
    const {customer, settings} = thunkAPI.getState()
    const {caseId, tcEmail, tcPhone} = customer.data


    const headers = {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'}
    const body = JSON.stringify({caseid: caseId, lang: settings.lang})
    const method = 'post'
    let ret
    if (tcEmail) {
        const response1 = await fetch(`${urlHost}/srv_calldata/sendTCLink.php`, {headers, body, method})
        ret = response1
    }

    if (tcPhone) {
        const state = thunkAPI.getState()
        const data = state.customer.data
        const tcPhone = data.tcPhone.replace(/-/g, '')
        await doWriteToRedis(JSON.stringify({...data, tcPhone}), state)

        const response2 = await fetch(`${urlHost}/srv_calldata/sendTCLinkSMS.php`, {headers, body, method})
        ret = response2
    }

    if (ret) {
        return ret.json()
    }
})

export const sendBroadbandFacts = createAsyncThunk('customer/sendBroadbandFacts', async (arg, thunkAPI) => {
    await thunkAPI.dispatch(writeToRedis())
    const {customer, settings} = thunkAPI.getState()
    const {caseId, tcEmail, tcPhone} = customer.data


    const headers = {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'}
    const body = JSON.stringify({caseid: caseId, lang: settings.lang})
    const method = 'post'
    let ret
    if (tcEmail) {
        const response1 = await fetch(`${urlHost}/srv_calldata/sendBroadbandLinkEmail.php`, {headers, body, method})
        ret = response1
    }

    if (tcPhone) {
        const state = thunkAPI.getState()
        const data = state.customer.data
        const tcPhone = data.tcPhone.replace(/-/g, '')
        await doWriteToRedis(JSON.stringify({...data, tcPhone}), state)

        const response2 = await fetch(`${urlHost}/srv_calldata/sendBroadbandLinkSMS.php`, {headers, body, method})
        ret = response2
    }

    if (ret) {
        return ret.json()
    }
})

async function twilioPauseOrResume(state, arg) {
    const {callSid} = state.customer.data
    const response = await fetch(`${urlHost}/srv_twillio/pause_resume_recording.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({callSid, recording_action: arg}),
        method: 'post',
    })

    return response.ok
}

async function alvariaPauseOrResume({embedded}, arg) {
    const host = process.env.REACT_APP_CRM_NEW_BASE_URL
    const response = await fetch(`${host}/api/v1/recordings/${arg}`, {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userId: embedded.alvariaUserId})
    })

    return response.ok
}

export const pauseOrResumeRecording = createAsyncThunk('customer/pauseOrResumeRecording', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const {embedded} = state
    if (embedded.alvariaUserId) {
        return alvariaPauseOrResume(state, arg)
    }
    return twilioPauseOrResume(state, arg)
})


export const getCCInfoFromTwillio = createAsyncThunk('customer/getCCInfoFromTwillio', async (arg, thunkAPI) => {
    const customer = thunkAPI.getState().customer
    const data = customer.data
    const response = await fetch(`${urlHost}/srv_twillio/ccdata.php`, {
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'},
        body: JSON.stringify({session_id: data.caseId.substring(1), agent_id: data.agentId}),
        method: 'post',
    })

    return response.json()
})

export const getTwilioPhoneNumber = createAsyncThunk('customer/getTwilioPhoneNumber', async (arg, thunkAPI) => {
    const customer = thunkAPI.getState().customer
    const data = customer.data
    const response = await fetch(`${urlHost}/srv_twillio/get_twillio_number.php`, {
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post',
    })

    if (response.ok) {
        return response.json()
    }
})

export const getGeoCode = createAsyncThunk('customer/getGeoCode', async (arg, thunkAPI) => {
    const url = `${urlHost}/srv_google/getGeocode.php?format=new`
    const state = thunkAPI.getState()
    const customer = state.customer
    const data = {...customer.data, epqPass: arg}
    const response = await fetch(url, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        method: 'post',
    })

    if (response.ok) {
        return response.json()
    }
})

export const storeCCInfo = createAsyncThunk('customer/storeCCInfo', async (arg, thunkAPI) => {
    await thunkAPI.dispatch(writeToRedis())
})

export const checkBRT = createAsyncThunk('customer/checkBRT', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data

    const landLinePhone = data.landLinePhone.replace(/-/g, '')

    await doWriteToRedis(JSON.stringify({...data, landLinePhone}), state)

    //const {caseId, landLinePhone} = data
    const {caseId} = data

    const response = await fetch(`${urlHost}/srv_brt/numberPrequalifyAvailabilityServiceRequest.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId, transid: caseId, tn: landLinePhone}),
        method: 'post'
    })

    if (response.ok) {
        return response.json()
    }
})

export const getFlexOfferAvailability = createAsyncThunk('customer/getFlexOfferAvailability', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const body = JSON.stringify(state.customer.data)

    const response = await fetch(`${urlHost}/srv_calldata/getFlexOfferAvailabilityEPQ.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'}, body, method: 'post'
    })

    if (response.ok) {
        return response.json()
    }
})

export const recordFusionCode = createAsyncThunk('customer/refusedFusion', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const {caseId} = state.customer.data

    const response = await fetch(`${urlHost}/srv_calldata/record_fusion_code.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    })

    if (response.ok) {
        return response.json()
    }
})

export const cancelReservation = createAsyncThunk('customer/cancelReservation', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const {caseId} = state.customer.data

    const response = await fetch(`${urlHost}/srv_calldata/cancel_reservation.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    })

    if (response.ok) {
        return response.json()
    }
})

export const sendDealerEmail = createAsyncThunk('customer/sendDealerEmail', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const response = await fetch(`${urlHost}/srv_calldata/new/sendDealerEmail.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify(arg),
        method: 'post'
    })

    if (response.ok) {
        return true
    }
    return false
})

export const sendMXNoServiceInfo = createAsyncThunk('customer/sendMXNoServiceInfo', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const {caseId, serviceAddress, agentlogin} = state.customer.data
    const data = {
        'caseid': caseId,
        'mx_name': `${serviceAddress.firstName} ${serviceAddress.lastName}`,
        'mx_address': `${serviceAddress.address}, ${serviceAddress.city} ${serviceAddress.country}`,
        'phone': serviceAddress.phoneNumber,
        'agent': agentlogin,
    }
    const response = await fetch(`${urlHost}/srv_calldata/setMX_no_service_info.php?format=new`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify(data),
        method: 'post'
    })

    if (response.ok) {
        return true
    }
    return false
})

export function setCaseData(action, state) {
    for (const key in action.payload) {
        state.data[key] = action.payload[key]
    }
}

export const writeCCAuditTrail = createAsyncThunk('customer/writeCCAuditTrail', async ({
                                                                                           varName,
                                                                                           varValue
                                                                                       }, thunkAPI) => {
    const data = thunkAPI.getState().customer.data
    const response = await fetch(`${urlHost}/srv_calldata/writeCCAuditTrail.php`, {
        method: 'post',
        body: JSON.stringify({caseid: data.caseId}),
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application/json'}
    })
    return response.ok
})

export const checkDishSubscriber = createAsyncThunk('customer/checkDishSubscriber', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const body = JSON.stringify(data)
    await doWriteToRedis(body, state)
    const {caseId} = data
    const init = {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: caseId}),
        method: 'post'
    }
    let path = 'srv_hughes/dish_openapi.php'
    const response = await fetch(`${urlHost}/${path}`, init)

    return response.json()
})

//changePriorityPackage
export const changePriorityPackage = createAsyncThunk('customer/changePriorityPackage', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const response = await fetch(`${urlHost}/srv_calldata/change_priority_package.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post'
    })
    return response.json()
})


export const showRegularPackage = createAsyncThunk('customer/showRegularPackage', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const response = await fetch(`${urlHost}/srv_calldata/show_regular_package.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post'
    })
    return response.json()
})

export const showNycafPackage = createAsyncThunk('customer/showNycafPackage', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const data = state.customer.data
    const response = await fetch(`${urlHost}/srv_calldata/show_nycaf_package.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify({caseid: data.caseId}),
        method: 'post'
    })
    return response.json()
})

export const sendPersonalInfoEmail = createAsyncThunk('customer/sendPersonalInfoEmail', async (arg, thunkAPI) => {
    const state = thunkAPI.getState()
    const response = await fetch(`${urlHost}/srv_calldata/sendPIILink.php`, {
        headers: {...getCredentialsHeader(state), 'Content-Type': 'application/json'},
        body: JSON.stringify(state.customer.data),
        method: 'post'
    })
    return response.json()
})

export const getLeaderboards = createAsyncThunk('customer/getLeaderboards', async (arg) => {
    const response = await fetch(`${process.env.REACT_APP_CRM_NEW_BASE_URL}/api/v1/leaderboards`)
    if (response.ok) {
        return response.json()
    }
})

export const setExtraReducers = (builder) => {
    const setUpFetchCase = () => {
        const {fulfilled} = fetchCase
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
            state.cases = []
            state.personalInfoLink = null
        })
    }

    const setUpWriteToRedis = () => {
        const {fulfilled, rejected} = writeToRedis
        builder.addCase(fulfilled, (state, action) => {
            console.log(action)
        })
        builder.addCase(rejected, (state, action) => {
            console.log(action)
        })
    }

    const setUpCreateNewCase = () => {
        const {rejected, fulfilled, pending} = createNewCase
        builder.addCase(pending, (state) => {
            state.requestedNewCase = true
        })
        builder.addCase(rejected, (state, action) => {
            state.applicationError = 'Failed to create a new case. Please contact support!'
        })
        builder.addCase(fulfilled, (state, action) => {
            state.applicationError = false
            setCaseData(action, state)
            state.cases = []
        })
    }

    const setUpRunEPQ = () => {
        const {pending, fulfilled, rejected} = runEPQ
        builder.addCase(pending, (state) => {
        })
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
        builder.addCase(rejected, (state) => {
        })
    }

    const setUpExtraChoices = () => {
        const {fulfilled} = writeExtraChoices
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpGetPlansVisualPage = () => {

        const mapServices = (input) => {
            const ret = []
            for (const e of input) {
                let key = e
                if (key.includes('/mo.')) {
                    key = key.substring(0, key.indexOf(':'))
                }
                ret.push({label: e, value: key})
            }
            return ret
        }

        const {fulfilled} = getPlansVisualPage
        builder.addCase(fulfilled, (state, action) => {
            const script = state.data.script?.toLowerCase()
            if (isMexico(state.data.program)) {
                state.mexicoOffer = action.payload[0]
                state.plans = action.payload[5]
            } else if (isBusiness(script)) {
                state.plans = action.payload[22]
                state.optionalNorton = mapServices(action.payload[24])
                state.optionalRepair = mapServices(action.payload[19])
                state.optionalStaticIP = mapServices(action.payload[20])
                state.optionalVoice = mapServices(action.payload[21])
                state.optionalMesh = mapServices(action.payload[25])

                state.offers = {
                    'infobox': action.payload[0],
                    'offerA': action.payload[1],
                    'offerB': action.payload[2],
                    'recommendation': action.payload[3],
                    'plan1': action.payload[4],
                    'plan2': action.payload[5],
                    'plan3': action.payload[6],
                    'plan4': action.payload[7],
                    'plan5': action.payload[8],
                    'plan6': action.payload[9],
                    'intersti1': action.payload[10],
                    'intersti2': action.payload[11],
                    'intersti3': action.payload[12],
                    'intersti4': action.payload[13],
                    'intersti5': action.payload[14],
                    'intersti6': action.payload[15],
                    'intersti7': action.payload[16],
                    'intersti8': action.payload[17],
                    'intersti9': action.payload[18],
                    'intersti10': action.payload[23],
                }

            } else if (isConsumer(script)) {
                state.optionalNorton = mapServices(action.payload[12])
                state.optionalRepair = mapServices(action.payload[13])
                state.optionalVoice = mapServices(action.payload[15])
                state.optionalMesh = mapServices(action.payload[20])
                state.plans = action.payload[16]

                state.offers = {
                    'offerA': action.payload[0],
                    'offerB': action.payload[1],
                    'recommendation': action.payload[2],
                    'plan1': action.payload[3],
                    'intersti1': action.payload[4],
                    'intersti2': action.payload[5],
                    'plan2': action.payload[6],
                    'infobox': action.payload[7],
                    'plan3': action.payload[8],
                    'plan4': action.payload[9],
                    'intersti3': action.payload[10],
                    'intersti4': action.payload[11],
                    'pse': action.payload[14],
                    'intersti5': action.payload[17],
                    'mailinrebate': action.payload[18],
                    'norton_discount_eligible': action.payload[19],
                    'plan5': action.payload[21],
                    'plan6': action.payload[22],
                    'intersti6': action.payload[23],
                    'plan7': action.payload[24],
                    'plan8': action.payload[25],
                    'plan9': action.payload[26],
                    'plan10': action.payload[27],
                    'plan11': action.payload[28],
                    'plan12': action.payload[29],

                }
            }
        })
    }

    const setUpReservationInstallDate = () => {
        const {fulfilled} = reserveInstallDate

        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpGetTotalCalculations = () => {
        const {fulfilled, rejected, pending} = getTotalCalculations
        builder.addCase(fulfilled, (state, action) => {
            state.cart = action.payload.cart
            setCaseData({payload: action.payload.data}, state)
            state.cartState = 'done'
        })
        builder.addCase(rejected, (state) => {
            state.cartState = null
        })
        builder.addCase(pending, (state) => {
            state.cartState = 'pending'
        })
    }

    const setUpProcessOrder = () => {
        const {fulfilled} = processOrder
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpCancelOrder = () => {
        const {fulfilled} = cancelOrder
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpSetNYB = () => {
        const {pending, rejected, fulfilled} = foreNYB
        builder.addCase(pending, (state) => {
            state.nybOfferStatus = 'pending'
        })
        builder.addCase(rejected, (state) => {
            state.nybOfferStatus = ''
        })
        builder.addCase(fulfilled, (state, action) => {
            state.nybOfferStatus = 'done'
            setCaseData(action, state)
        })
    }

    const setCheckDishSubscriber = () => {
        const {pending, rejected, fulfilled} = checkDishSubscriber
        builder.addCase(pending, (state) => {
            state.checkDishSubscriberState = 'pending'
        })
        builder.addCase(rejected, (state) => {
            state.checkDishSubscriberState = ''
        })
        builder.addCase(fulfilled, (state, action) => {
            state.checkDishSubscriberState = 'done'
            if (action.payload) {
                state.showDshPimMessage = action.payload
            }
            setCaseData(action, state)
        })
    }

    const setSendPreviewOrder = () => {
        const {pending, fulfilled, rejected} = sendPreviewOrder
        builder.addCase(pending, state => {
            state.sendPreviewState = 'pending'
        })
        builder.addCase(fulfilled, (state, action) => {
            state.sendPreviewState = 'done'
            if (action.payload) {
                state.sendPreviewLink = action.payload
            }
        })
        builder.addCase(rejected, state => {
            state.sendPreviewState = ''
        })
    }

    const setSendBroadbandLink = () => {
        const {pending, fulfilled, rejected} = sendBroadbandFacts
        builder.addCase(pending, state => {
            state.sendBroadbandFactsState = 'pending'
        })
        builder.addCase(fulfilled, (state, action) => {
            state.sendBroadbandFactsState = 'done'
            if (action.payload) {
                state.sendBroadbandFactsLink = action.payload
            }
        })
        builder.addCase(rejected, state => {
            state.sendBroadbandFactsState = ''
        })
    }

    const setGetTwillioNumber = () => {
        const {fulfilled, pending, rejected} = getTwilioPhoneNumber
        builder.addCase(fulfilled, (state, action) => {
            const payload = action.payload
            state.twillioNumberState = 'done'
            if (!payload) {
                return
            }
            state.twillioNumber = payload
        })
        builder.addCase(pending, (state) => {
            state.twillioNumberState = 'pending'
        })
        builder.addCase(rejected, (state) => {
            state.twillioNumberState = ''
        })
    }

    const setGetCCInfoFromTwilio = () => {
        const {fulfilled, pending} = getCCInfoFromTwillio
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload.error) {
                return
            }
            state.data.creditCard.creditNumber = action.payload.cc_num
            state.data.creditCard.ccv = action.payload.cc_cvv
            state.data.creditCard.expireMonth = action.payload.cc_exp_month
            state.data.creditCard.expireYear = action.payload.cc_exp_year
            state.data.creditCard.paymentType = action.payload.paymenttype
            state.twillioCCInfoState = 'done'
        })
        builder.addCase(pending, (state) => {
            state.twillioCCInfoState = 'pending'
        })
    }

    const setCheckExistingCases = () => {
        const {fulfilled} = checkExistingCases
        builder.addCase(fulfilled, (state, action) => {
            const cases = action.payload
            state.cases = cases
        })
    }

    const setSetCheckBusinessAddress = () => {
        const {fulfilled} = setCheckBusinessAddress
        builder.addCase(fulfilled, (state, action) => {
            state.checkBusinessAddress = action.payload
        })
    }

    const setInitBusinessFromConsumer = () => {
        const {fulfilled} = initBusinessFromConsumer
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setGetGeoCode = () => {
        const {fulfilled} = getGeoCode
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setSetLKP = () => {
        const {fulfilled} = setLKP
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }

    const setUpStoreCCInfo = () => {
        const {fulfilled} = storeCCInfo
        builder.addCase(fulfilled, (state) => {
            state.showOrderButton = true
        })
    }

    const setCheckBRT = () => {
        const {fulfilled} = checkBRT
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }

    const setGetFlexOfferAvailability = () => {
        const {fulfilled} = getFlexOfferAvailability
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }

    const setCancelReservation = () => {
        const {fulfilled} = cancelReservation
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }

    const setReAssignCase = () => {
        const {fulfilled} = reAssignCase
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
            state.cases = []
        })
    }

    const setUpSendLeadToSelaForce = () => {
        const {fulfilled} = sendLeadToSaleForce
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                state.leadSentToSaleForce = true
            }
        })
    }

    const setFetchCCInfo = () => {
        const {fulfilled} = fetchCCInfo
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }

    const setRecordFusionCode = () => {
        const {fulfilled} = recordFusionCode
        builder.addCase(fulfilled, (state, action) => {
            if (action.payload) {
                setCaseData(action, state)
            }
        })
    }


    const setValidateEmail = () => {
        const {fulfilled} = validateEmail
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setPriorityPackage = () => {
        const {fulfilled} = changePriorityPackage
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpSendPersonalInfoEmail = () => {
        const {fulfilled, pending} = sendPersonalInfoEmail

        builder.addCase(fulfilled, (state, action) => {
            state.personalInfoLink = action.payload
            state.sendingPersonalInfoLink = false
        })
        builder.addCase(pending, (state) => {
            state.sendingPersonalInfoLink = true
        })
    }

    const setUpShowNycafPackage = () => {
        const {fulfilled} = showNycafPackage
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }
    const setUpShowRegularPackage = () => {
        const {fulfilled} = showRegularPackage
        builder.addCase(fulfilled, (state, action) => {
            setCaseData(action, state)
        })
    }

    const setUpLeaderboards = () => {
        const {fulfilled} = getLeaderboards
        builder.addCase(fulfilled, (state, action) => {
            state.leaderboards = action.payload
        })
    }


    setUpFetchCase()
    setUpWriteToRedis()
    setUpCreateNewCase()
    setUpRunEPQ()
    setUpExtraChoices()
    setUpGetPlansVisualPage()
    setUpReservationInstallDate()
    setUpGetTotalCalculations()
    setUpProcessOrder()
    setUpSetNYB()
    setSendPreviewOrder()
    setGetTwillioNumber()
    setGetCCInfoFromTwilio()
    setCheckExistingCases()
    setSetCheckBusinessAddress()
    setInitBusinessFromConsumer()
    setGetGeoCode()
    setSetLKP()
    setUpStoreCCInfo()
    setCheckBRT()
    setGetFlexOfferAvailability()
    setCancelReservation()
    setReAssignCase()
    setUpSendLeadToSelaForce()
    setFetchCCInfo()
    setRecordFusionCode()
    setSendBroadbandLink()
    setUpCancelOrder()
    setValidateEmail()
    setCheckDishSubscriber()
    setPriorityPackage()
    setUpSendPersonalInfoEmail()
    setUpShowNycafPackage()
    setUpShowRegularPackage()
    setUpLeaderboards()

}
