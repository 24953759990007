import {useDispatch, useSelector} from 'react-redux'
import {setNotesSection} from '../../state/settingsSlice'
import {takeScreenShot} from '../../functions'

const Wrapper = (props) => {
    const dispatch = useDispatch()
    const currentSection = useSelector(state => state.settings.notesSection)
    const script = useSelector(state => state.customer.data.script)
    const mediaType = useSelector(state => state.customer.data.mediaType)
    const caseId = useSelector(state => state.customer.data.caseId)

    const mouseOver = (e) => {
        if (currentSection !== props.section) {
            if (currentSection !== 'collect-credit-card') {
                takeScreenShot(caseId)
            }
            dispatch(setNotesSection({section: props.section, script, mediaType}))
        }
        e.stopPropagation()
    }

    return (
        <div onMouseOver={mouseOver} id={props.section}>
            {props.children}
        </div>
    )
}

export const connect = (Component, section) => {
    return (props) => (
        <Wrapper section={section}>
            <Component {...props}/>
        </Wrapper>
    )
}
