export const ArrowRightCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
            <path
                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
        </svg>
    )
}

export const ArrowLeftCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
            <path
                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
    )
}

export const ArrowClockwise = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
             className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
            <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
        </svg>
    )
}

export const Barchart = () => {
    return <svg style={{fill: 'white'}} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1"
                viewBox="0 0 24 24" width="16"
                height="16">
        <path
            d="M13,0h-2c-1.1,0-2,.9-2,2V24h6V2c0-1.1-.9-2-2-2Zm1,23h-4V2c0-.55,.45-1,1-1h2c.55,0,1,.45,1,1V23ZM22,6h-2c-1.1,0-2,.9-2,2V24h6V8c0-1.1-.9-2-2-2Zm1,17h-4V8c0-.55,.45-1,1-1h2c.55,0,1,.45,1,1v15ZM4,12H2c-1.1,0-2,.9-2,2v10H6V14c0-1.1-.9-2-2-2Zm1,11H1V14c0-.55,.45-1,1-1h2c.55,0,1,.45,1,1v9Z"/>
    </svg>

}

export const Help = () => {
    return <svg style={{fill: 'white'}} enableBackground="new 0 0 50 50" height="16px" id="Layer_1" version="1.1"
                viewBox="0 0 50 50"
                width="50px" xmlns="http://www.w3.org/2000/svg">
        <circle cx="25" cy="25" fill="none" r="24" stroke="white" strokeLinecap="round" strokeMiterlimit="10"
                strokeWidth="2"/>
        <rect fill="none" height="50" width="50"/>
        <g>
            <path
                d="M23.533,30.407v-1.47c0-1.436,0.322-2.188,1.075-3.229l2.404-3.3c1.254-1.721,1.684-2.546,1.684-3.766   c0-2.044-1.434-3.335-3.479-3.335c-2.008,0-3.299,1.219-3.729,3.407c-0.036,0.215-0.179,0.323-0.395,0.287l-2.259-0.395   c-0.216-0.036-0.323-0.179-0.288-0.395c0.539-3.443,3.014-5.703,6.744-5.703c3.872,0,6.49,2.546,6.49,6.097   c0,1.722-0.608,2.977-1.828,4.663l-2.403,3.3c-0.717,0.968-0.933,1.47-0.933,2.689v1.147c0,0.215-0.143,0.358-0.358,0.358h-2.367   C23.676,30.766,23.533,30.622,23.533,30.407z M23.354,33.851c0-0.215,0.143-0.358,0.359-0.358h2.726   c0.215,0,0.358,0.144,0.358,0.358v3.084c0,0.216-0.144,0.358-0.358,0.358h-2.726c-0.217,0-0.359-0.143-0.359-0.358V33.851z"/>
        </g>
    </svg>
}

export const Phone = () => {
    return <svg fill="white" height="16" viewBox="0 0 48 48" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h48v48h-48z" fill="none"/>
        <path
            d="M13.25 21.59c2.88 5.66 7.51 10.29 13.18 13.17l4.4-4.41c.55-.55 1.34-.71 2.03-.49 2.24.74 4.65 1.14 7.14 1.14 1.11 0 2 .89 2 2v7c0 1.11-.89 2-2 2-18.78 0-34-15.22-34-34 0-1.11.9-2 2-2h7c1.11 0 2 .89 2 2 0 2.49.4 4.9 1.14 7.14.22.69.06 1.48-.49 2.03l-4.4 4.42z"/>
    </svg>
}

export const Reset = () => {
    return <svg height="16" viewBox="0 0 48 48" width="16" xmlns="http://www.w3.org/2000/svg">
        <path fill="white"
            d="M38 12.83L35.17 10 24 21.17 12.83 10 10 12.83 21.17 24 10 35.17 12.83 38 24 26.83 35.17 38 38 35.17 26.83 24z"/>
        <path d="M0 0h48v48H0z" fill="none"/>
    </svg>
}

export const Switch = () => {
    return <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 7L20 7M20 7L16 3M20 7L16 11M16 17L4 17M4 17L8 21M4 17L8 13" stroke="white" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2"/>
    </svg>
}
