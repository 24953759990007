import {connect} from '../Notes/wrapper'
import {ProcessOrderButton} from './ProcessOrderButton'
import {OrderMessage} from './OrderMessage'
import ReviewContactInfo from '../AddressCollection/ReviewContactInfo'
import {OrderSummary} from "./OrderSummary";

export const PreOrderSection = () => {

    return <>
        <ReviewContactInfo/>
        <OrderSummary/>
        <ProcessOrderButton/>
        <OrderMessage/>
    </>
}

export default connect(PreOrderSection, 'pre-order')
