import {Form} from 'react-bootstrap'
import './form.scss'

export const FormLabel = (props) => {
    let requiredAsterisk = null
    if (props.required) {
        requiredAsterisk = <span style={{marginLeft: '2px', color: 'red'}}>*</span>
    }

    const className = []
    if (props.bold || !props.hasOwnProperty('bold')) {
        className.push('bold-text')
    }

    return <Form.Label className={className}>
        {props.children}
        {requiredAsterisk}
    </Form.Label>
}


export const FormCheck = (props) => {
    const onClick = () => {
        if (props.disabled){
            return
        }
        const payload = {target: {value: props.value, name: props.name, checked: !props.checked}}
        if (props.onClick) {
            props.onClick(payload)
        } else if (props.onChange) {
            props.onChange(payload)
        }
    }

    const className = []
    if (props.bold) {
        className.push('bold-text')
    }

    return <Form.Check>
        <Form.Check.Input type={props.type} checked={props.checked} onChange={props.onChange} onClick={props.onClick}
                          value={props.value} name={props.name} disabled={props.disabled} />
        <Form.Check.Label onClick={onClick} className={className}>{props.label}</Form.Check.Label>
    </Form.Check>
}
