import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import {Alert, Col} from 'react-bootstrap'
import * as Sentry from '@sentry/react'
import {environment, urlHost} from '../../constants'
import {replaceVars} from '../../functions'
import './scripts.scss'

let fetchingContent = false
const RemoteComponent = (props) => {
    const caseId = useSelector(state => state.customer.data.caseId)
    const [html, setHtml] = useState(null)

    const fetchHtml = async () => {
        let url = new URL(props.url)
        if (environment === 'local') {
            url = new URL(`http://localhost:8080/${url.pathname}${url.search}`)
        } else {
            url.hostname = (new URL(urlHost)).hostname
        }
        url.searchParams.append('caseId', caseId)

        const ret = await fetch(url)

        if (!ret.ok) {
            return
        }

        setHtml(await ret.text())
    }

    useEffect(() => {
        if (fetchingContent) {
            return
        }
        fetchingContent = true
        fetchHtml().finally(() => fetchingContent = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.url])

    if (html == null) {
        return <></>
    }

    return <div dangerouslySetInnerHTML={{__html: html}}></div>
}

const OfferComponent = (props) => {
    const offers = useSelector(state => state.customer.offers)

    let component
    if (props.offer && offers !== null && offers.hasOwnProperty(props.offer)) {
        component = <div className="offer-content" dangerouslySetInnerHTML={{__html: offers[props.offer]}}></div>
    }

    return component
}

// eslint-disable-next-line no-unused-vars
export const getSectionContent = (selectedNote, data = {}) => {
    if (!selectedNote) {
        return null
    }
    return selectedNote.content.map(c => {
        try {
            // eslint-disable-next-line no-eval
            const ret = eval(c.condition)
            if (!ret) {
                return null
            }
        } catch (e) {
            Sentry.withScope(scope => {
                scope.setFingerprint(['section-notes'])
                scope.setExtra('c.condition', c.condition)
                scope.setExtra('c.id', c.id)
                Sentry.captureException(e)
            })
        }

        let text = c.text
        if (c.type === 'remote') {
            return <RemoteComponent key={c.id} url={text}/>
        }
        if (c.type === 'offer') {
            return <OfferComponent key={c.id} offer={text}/>
        }

        try {
            text = replaceVars(data, c.text)
        } catch (e) {
            console.error(e)
        }

        return <div key={c.id} dangerouslySetInnerHTML={{__html: text}}></div>
    })
}

export const Notes = () => {
    const notesSection = useSelector(state => state.settings.notesSection)
    const selectedNote = useSelector(state => state.settings.selectedNote)
    const data = useSelector(state => state.customer.data)

    return (
        <Col md={6} className="scripts">
            {selectedNote ?
                <div style={{backgroundColor: selectedNote.background, padding: '10px'}}>
                    {selectedNote.content ?
                        getSectionContent(selectedNote, data) : null
                    } :
                </div>
                : <div>
                    <h1>{notesSection}</h1>
                    <Alert variant="warning">No notes for this section</Alert>
                </div>}
        </Col>

    )
}
